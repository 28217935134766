import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import About from "./Pages/About/About";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import SignUpPage from "./Pages/SignUpPage/SignUpPage";
import RegisterOwner from "./Pages/SignUpPage/RegisterOwner";
import AllLocations from "./Pages/AllLocations/AllLocations";
import BoatDetail from "./Pages/BoatDetail/BoatDetail";
import { useDispatch, useSelector } from "react-redux";
// import DashboardSideNav from "./DashboardComponents/DashboardSideNav/DashboardSideNav";
// import DahboardNavbar from "./DashboardComponents/DahboardNavbar/DahboardNavbar";
import AllPropertyInquiry from "./DashboardComponents/AllPropertyInquiry/AllPropertyInquiry";
import { Box } from "@mui/material";
import AllBoatInquiry from "./DashboardComponents/AllBoatInquiry/AllBoatInquiry";
import AllProperty from "./DashboardComponents/AllProperty/AllProperty";
import RoomCategory from "./DashboardComponents/RoomCategory/RoomCategory";
import CreateRoomCategory from "./DashboardComponents/RoomCategory/CreateRoomCategory";
import AllBookedProperty from "./DashboardComponents/AllBookedProperty/AllBookedProperty";
import PendingBookedProperty from "./DashboardComponents/PendingBookedProperty/PendingBookedProperty";
import SuccessBookedProperty from "./DashboardComponents/SuccessBookedProperty/SuccessBookedProperty";
import CancelBookedProperty from "./DashboardComponents/CancelBookedProperty/CancelBookedProperty";
import AllBoat from "./DashboardComponents/AllBoat/AllBoat";
import AllBookedBoat from "./DashboardComponents/AllBookedBoat/AllBookedBoat";
import PendingBookedBoat from "./DashboardComponents/PendingBookedBoat/PendingBookedBoat";
import SuccessBookedBoat from "./DashboardComponents/SuccessBookedBoat/SuccessBookedBoat";
import CancelBookedBoat from "./DashboardComponents/CancelBookedBoat/CancelBookedBoat";
import WithdrawMoney from "./DashboardComponents/WithdrawMoney/WithdrawMoney";
import WithdrawPreview from "./DashboardComponents/WithdrawMoney/WithdrawPreview";
import WithdrawLog from "./DashboardComponents/WithdrawLog/WithdrawLog";
import OpenTicket from "./DashboardComponents/OpenTicket/OpenTicket";
import MyTicket from "./DashboardComponents/MyTicket/MyTicket";
import DashboardProfile from "./DashboardComponents/DashboardProfile/DashboardProfile";
import FASecurity from "./DashboardComponents/FASecurity/FASecurity";
import DashboardLogout from "./DashboardComponents/DashboardLogout/DashboardLogout";
import LoginPage from "./Pages/login";
import AddNewProperty from "./DashboardComponents/AddNewProperty/AddNewProperty";
import CreateNewBoat from "./DashboardComponents/CreateNewBoat/CreateNewBoat";
import UpdateBoat from "./DashboardComponents/UpdateBoat/UpdateBoat";
import UpdateProperty from "./DashboardComponents/UpdateProperty/UpdateProperty";
import ErrorPage404 from "./DashboardComponents/ErrorPage404/ErrorPage404";
import LandingPage from "./Pages/landing page";
import ContactUsPage from "./Pages/ContactUsPage/ContactUsPage";
import PropertyHistory from "./UserDashboardComponent/PropertyHistory/PropertyHistory";
import BoatBookingHistory from "./UserDashboardComponent/BoatBookingHistory/BoatBookingHistory";
import Requests from "./UserDashboardComponent/Requests/Requests";
import Reviews from "./UserDashboardComponent/Reviews/Reviews";
import AllPendingReviews from "./UserDashboardComponent/Reviews/AllPendingReviews";
import AllCompletedReviews from "./UserDashboardComponent/Reviews/AllCompletedReviews";
import SupportTickets from "./UserDashboardComponent/SupportTickets/SupportTickets";
import NewSupportTickets from "./UserDashboardComponent/NewSupportTickets/NewSupportTickets";
import ChangePassword from "./UserDashboardComponent/ChangePassword/ChangePassword";
import LoginSecurity from "./UserDashboardComponent/LoginSecurity/LoginSecurity";
import TermsOfServices from "./UserDashboardComponent/TermsOfServices/TermsOfServices";
import PrivacyPolicy from "./UserDashboardComponent/PrivacyPolicy/PrivacyPolicy";
import PrivacyPolicyStatic from "./UserDashboardComponent/PrivacyPolicy/PrivacyPolicyStatic";
import TermsPolicyStatic from "./UserDashboardComponent/PrivacyPolicy/TermsPolicyStatic";
import EmailWeb from "./UserDashboardComponent/EmailWeb/EmailWeb";
import AccomodationPage from "./Pages/accomodation";
import BlogPage from "./Pages/blogs";
import BlogDetails from "./Pages/blogs/BlogDetail";
import { userLogged } from "./UserDashboardComponent/UserProfile/userSlice";
import BoatPage from "./Pages/boat";
import AccomodationDetails from "./Pages/accomodation/Details";
import Rooms from "./Pages/accomodation/Rooms";
import OwnerChat from "./Pages/chatbot/owner/OwnerChat";
import UserChat from "./Pages/chatbot/user/UserChat";
import OwnerLogin from "./Pages/login/OwnerLogin";
import BookingConfirmed from "./Pages/BookingConfirmed/BookingConfirmed";
import Wishlist from "./UserDashboardComponent/Wishlist/Wishlist";
import UserProfile from "./UserDashboardComponent/UserProfile/UserProfile";
import UserProfileEdit from "./UserDashboardComponent/UserProfileEdit/UserProfileEdit";
import Dashboard from "./DashboardComponents/Dashboard/Dashboard";
import EditRoomCategory from "./DashboardComponents/RoomCategory/EditRoomCategory";
import Seo from "./Components/Seo";
import DashboardHome from "./DashboardComponents/DashboardHome/DashboardHome";

function App() {
  const dispatch = useDispatch();
  let token = localStorage.getItem("bearerToken");
  let userType = localStorage.getItem("userType");
  const { isLoggedIn } = useSelector((state) => state.user);

  useEffect(() => {
    if (token && userType) {
      dispatch(userLogged(userType));
    }
  }, []);

  const OwnerRoutes = () => {
    return (
      <>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <Routes>
              <Route exact path="/chat" element={<OwnerChat />} />
              <Route exact path="/" element={<Navigate to="/dashboard" />} />
              <Route
                exact
                path="/dashboard"
                element={<Dashboard />}
                // element={<Navigate to="/property-inquiry" />}
              />
              <Route
                exact
                path="/dashboard-home"
                element={<DashboardHome />}
                // element={<Navigate to="/property-inquiry" />}
              />
              <Route
                exact
                path="/property-inquiry"
                element={<AllPropertyInquiry />}
              />
              <Route exact path="/boat-inquiry" element={<AllBoatInquiry />} />
              <Route exact path="/all-property" element={<AllProperty />} />
              <Route exact path="/room-category" element={<RoomCategory />} />
              <Route
                exact
                path="/create-room-category"
                element={<CreateRoomCategory />}
              />
              <Route
                exact
                path="/edit-room-category/:id"
                element={<EditRoomCategory />}
              />
              <Route
                exact
                path="/booked-property"
                element={<AllBookedProperty />}
              />
              <Route
                exact
                path="/pending-property"
                element={<PendingBookedProperty />}
              />
              <Route
                exact
                path="/success-property"
                element={<SuccessBookedProperty />}
              />
              <Route
                exact
                path="/cancel-property"
                element={<CancelBookedProperty />}
              />
              <Route exact path="/add-property" element={<AddNewProperty />} />
              <Route
                exact
                path="/property/:id/edit"
                element={<UpdateProperty />}
              />
              <Route exact path="/all-boat" element={<AllBoat />} />
              <Route exact path="/add-boat" element={<CreateNewBoat />} />
              {/* <Route exact path="/update-boat" element={<UpdateBoat />} /> */}
              <Route exact path="/boat/:id/edit" element={<UpdateBoat />} />
              <Route exact path="/booked-boat" element={<AllBookedBoat />} />
              <Route
                exact
                path="/pending-boat"
                element={<PendingBookedBoat />}
              />
              <Route
                exact
                path="/success-boat"
                element={<SuccessBookedBoat />}
              />
              <Route exact path="/cancel-boat" element={<CancelBookedBoat />} />
              <Route exact path="/withdraw-money" element={<WithdrawMoney />} />
              <Route
                exact
                path="/withdraw-preview"
                element={<WithdrawPreview />}
              />
              <Route exact path="/withdraw-log" element={<WithdrawLog />} />
              <Route exact path="/open-ticket" element={<OpenTicket />} />
              <Route exact path="/my-ticket" element={<MyTicket />} />
              <Route exact path="/profile" element={<DashboardProfile />} />
              <Route exact path="/security" element={<FASecurity />} />
              <Route exact path="/logout" element={<DashboardLogout />} />
              {/* <Route exact path="/*" element={<Navigate to="/property-inquiry" />}/> */}
            </Routes>
          </div>
        </div>

        {/* Guest */}
        {/* <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/accomodation" element={<AccomodationPage />} />
      <Route exact path="/accomodation/search-location/:id/:slug" element={<AccomodationPage />}/>
      <Route exact path="/search" element={<AccomodationPage />} />
      <Route exact path="/accomodation/:id/:slug/details" element={<AccomodationDetails />}/>
      <Route exact path="/accomodation/:id/:slug/rooms/:room_id" element={<Rooms />}/>
      <Route exact path="/boat" element={<BoatPage />} />
      <Route exact path="/boatDetail" element={<BoatDetail />} />
      <Route exact path="/boat/:id/:slug/details" element={<BoatDetail />} />
      <Route exact path="/blog" element={<BlogPage />} />
      <Route exact path="/blog/:id/:slug" element={<BlogDetails />} />
      <Route exact path="/contact" element={<ContactUsPage />} />
      <Route exact path="/about-us" element={<About />} />
      <Route exact path="/terms-services" element={<TermsOfServices />} />
      </Routes> */}
      </>
    );
  };

  const UserRoutes = () => {
    return (
      <Routes>
        <Route exact path="/chat/:item?/:id?/:slug?" element={<UserChat />} />
        {/* <Route exact path="/" element={<UserDashboard />} /> */}
        {/* <Route exact path="/test" element={<AccomodationEntry1 />} /> */}
        <Route exact path="/booking-confirmed" element={<BookingConfirmed />} />
        {/* <Route exact path="/*" element={<Navigate to="/dashboard" />} /> */}
        <Route exact path="/profile" element={<UserProfileEdit />} />
        <Route exact path="/profile-edit" element={<UserProfile />} />
        <Route exact path="/dashboard" element={<Requests />} />
        <Route exact path="/availability" element={<Requests />} />
        <Route exact path="/property-history" element={<PropertyHistory />} />
        <Route exact path="/boat-history" element={<BoatBookingHistory />} />
        <Route exact path="/user-reviews" element={<Reviews />} />
        <Route exact path="/wishlist" element={<Wishlist />} />
        <Route
          exact
          path="/user-pending-reviews/:type"
          element={<AllPendingReviews />}
        />
        <Route
          exact
          path="/user-completed-reviews/:type"
          element={<AllCompletedReviews />}
        />
        <Route exact path="/support-ticket" element={<SupportTickets />} />
        <Route exact path="/new-ticket" element={<NewSupportTickets />} />
        <Route exact path="/change-password" element={<ChangePassword />} />
        <Route exact path="/login-security" element={<LoginSecurity />} />
        {/* Guest */}
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/locations" element={<AllLocations />} />
        <Route exact path="/accomodation" element={<AccomodationPage />} />
        <Route
          exact
          path="/accomodation/search-location/:id/:slug"
          element={<AccomodationPage />}
        />
        <Route exact path="/search" element={<AccomodationPage />} />
        <Route
          exact
          path="/accomodation/:id/:slug/details"
          element={<AccomodationDetails />}
        />
        <Route
          exact
          path="/accomodation/:id/:slug/rooms/:room_id"
          element={<Rooms />}
        />
        <Route exact path="/boat" element={<BoatPage />} />
        <Route
          exact
          path="/boat/search-location/:id/:slug"
          element={<BoatPage />}
        />
        <Route exact path="/boatDetail" element={<BoatDetail />} />
        <Route exact path="/boat/:id/:slug/details" element={<BoatDetail />} />
        <Route exact path="/blog" element={<BlogPage />} />
        <Route exact path="/blog/:id/:slug" element={<BlogDetails />} />
        <Route exact path="/contact" element={<ContactUsPage />} />
        <Route exact path="/about-us" element={<About />} />
        {/* <Route exact path="/terms-services" element={<TermsOfServices />} /> */}
        <Route exact path="/privacy-policy" element={<PrivacyPolicyStatic />} />
        <Route
          exact
          path="/terms-of-services"
          element={<TermsPolicyStatic />}
        />
      </Routes>
    );
  };

  return (
    <div>
      <Seo />
      {!isLoggedIn && (!token || !userType) && (
        <Box>
          <Routes>
            {/* <Route exact path="/test" element={<BookingConfirmed />} /> */}
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/about-us" element={<About />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/owner-login" element={<OwnerLogin />} />
            <Route exact path="/forgetPassword" element={<ForgetPassword />} />
            <Route exact path="/register" element={<SignUpPage />} />
            <Route exact path="/owner-register" element={<RegisterOwner />} />
            <Route exact path="/error-page" element={<ErrorPage404 />} />
            <Route exact path="/email-web" element={<EmailWeb />} />
            <Route exact path="/terms-services" element={<TermsOfServices />} />
            <Route exact path="/policy/:id/:slug" element={<PrivacyPolicy />} />
            <Route
              exact
              path="/privacy-policy"
              element={<PrivacyPolicyStatic />}
            />
            <Route
              exact
              path="/terms-of-services"
              element={<TermsPolicyStatic />}
            />
            <Route exact path="/locations" element={<AllLocations />} />
            <Route exact path="/boat" element={<BoatPage />} />
            <Route
              exact
              path="/boat/search-location/:id/:slug"
              element={<BoatPage />}
            />
            <Route exact path="/boatDetail" element={<BoatDetail />} />
            <Route
              exact
              path="/boat/:id/:slug/details"
              element={<BoatDetail />}
            />
            <Route exact path="/accomodation" element={<AccomodationPage />} />
            <Route
              exact
              path="/accomodation/search-location/:id/:slug"
              element={<AccomodationPage />}
            />
            <Route exact path="/search" element={<AccomodationPage />} />
            <Route
              exact
              path="/accomodation/:id/:slug/details"
              element={<AccomodationDetails />}
            />
            <Route
              exact
              path="/accomodation/:id/:slug/rooms/:room_id"
              element={<Rooms />}
            />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:id/:slug" element={<BlogDetails />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route exact path="*" element={<Navigate to="/" />} />
            {/* </Routes> */}
          </Routes>
        </Box>
      )}

      {isLoggedIn === "user" && (
        <Routes>
          <Route path="/*" element={<UserRoutes />} />
        </Routes>
      )}
      {isLoggedIn === "owner" && (
        <Routes>
          <Route path="/*" element={<OwnerRoutes />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
