import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import makeHttpRequest from "../../utils/api";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import Loader from "../../Components/Loader";
import Toaster from "../../Components/Toaster";
import ExtensionApi from "../../Components/ExtensionApi";
import RegisterImage from "../../Components/RegisterImage";
import { useLanguage } from "../../utils/LanguageContext";
import "../../main.css";
import Navbar from "../../Components/Navbar/Navbar";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ username: "", password: "" });
  const { translate } = useLanguage();
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);

  useEffect(() => {
    // Reset the form state when the component mounts
    setState({ username: "", password: "" });
    setIsCaptchaPassed(false);
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const responseGoogleSuccess = async (response) => {
    try {
      const { credential } = response;
      const res = await makeHttpRequest("POST", "auth/google/callback", {
        token: credential,
        user_type: "user",
      });
      setLoggedUser(res);
    } catch (error) {
      console.error(error);
      Toaster("error", "Google login failed");
    }
  };

  const responseGoogleFailure = (error) => {
    console.error(error);
    Toaster("error", "Google login failed");
  };

  const handleLogin = async () => {
    if (!isCaptchaPassed) {
      Toaster("warning", "Captcha Required");
      return;
    }

    const { username, password } = state;
    if (!username) {
      Toaster("info", "Enter username");
      return;
    }
    if (!password) {
      Toaster("info", "Enter password");
      return;
    }

    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "login", {
        username,
        password,
        user_type: "user",
      });
      setLoggedUser(res);
    } catch (error) {
      console.error("Login error:", error);
      Toaster("error", "Login failed");
    } finally {
      setLoading(false);
    }
  };

  const setLoggedUser = (res) => {
    if (res?.success) {
      localStorage.setItem("bearerToken", res.data.token);
      localStorage.setItem("userType", res.data.type);
      localStorage.setItem("userEmail", res.data.user?.email);
      dispatch(userLogged(res.data.type === "user" ? "user" : "owner"));
      navigate("/dashboard");
    } else {
      Toaster("error", "Invalid credentials");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="login-content-wrapper mt-4 pt-4">
              <div className="login-heading">
                <img src="/assets/icons/companyLogo.png" alt="..." />
                <div className="SignUpHeading-topIcon">
                  <img src="/assets/icons/aa1.png" alt="..." />
                </div>
                <div className="SignUpHeading-bottomIcon">
                  <img src="/assets/icons/aa2.jpeg" alt="..." />
                </div>
                <h1>{translate("Welcome to CAPRIEASY")}</h1>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleLogin();
                  }}
                >
                  <div className="login-email">
                    <label>{translate("Email")}</label>
                    <input
                      name="username"
                      type="text"
                      placeholder={translate("Enter User")}
                      ref={usernameRef}
                      value={state.username}
                      onChange={handleInput}
                      onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                      autoComplete="username"
                    />
                  </div>

                  <div className="login-password">
                    <label>{translate("Password")}</label>
                    <input
                      name="password"
                      type="password"
                      placeholder={translate("Enter your password")}
                      ref={passwordRef}
                      value={state.password}
                      onChange={handleInput}
                      onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                      autoComplete="current-password"
                    />
                  </div>
                  <div className="login-password mt-3 mb-3">
                    <ExtensionApi CaptchaPassed={setIsCaptchaPassed} />
                  </div>
                  <div className="remember-wrapper">
                    <div className="form-check remember-me">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {translate("Remember for 30 days")}
                      </label>
                    </div>

                    <Link
                      style={{ fontSize: "16px", color: "#45AAF0" }}
                      to="/forgetPassword"
                    >
                      {translate("Forget Password")}
                    </Link>
                  </div>

                  <div className="signIn-btn">
                    {loading ? (
                      <Loader size={40} />
                    ) : (
                      <button type="submit">{translate("Login In")}</button>
                    )}
                  </div>
                </form>

                <div className="google-login">
                  <GoogleLogin
                    text={translate("Sign in with Google")}
                    onSuccess={responseGoogleSuccess}
                    onError={responseGoogleFailure}
                    cancel_on_tap_outside
                  />
                </div>

                <div className="signUp-link">
                  <p>
                    {translate("Don't have an account?")}{" "}
                    <Link
                      style={{ fontSize: "16px", color: "#45AAF0" }}
                      to="/register"
                    >
                      {translate("Sign Up")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 d-lg-block d-none mt-4 pt-4"
            style={{ height: "100%" }}
          >
            <RegisterImage type={"login"} />
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
