import React, { useEffect, useState } from "react";
import "./BlogPage.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { BsArrowRightShort } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import makeHttpRequest from "../../utils/api";
import { addBlogs, selectBlog } from "./blogSlice";
import { useDispatch, useSelector } from "react-redux";
import { setEqualHeight } from "../../utils/heightUtils";
import { useLanguage } from "../../utils/LanguageContext";
import { MdNavigateNext } from "react-icons/md";

const Title = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "60px",
  fontWeight: 700,
  textAlign: "center",
  marginTop: "50px",
});

function BlogPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogs } = useSelector((state) => state.blogs);
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();

  useEffect(() => {
    setEqualHeight(".same-height");
    getData();
  }, []);

  const handleDetails = (item) => {
    dispatch(selectBlog(item));
    navigate(`/blog/${item?.id}/${item?.slug}`);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "blog");
      dispatch(addBlogs(response.data));
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };
  return (
    <Box>
      <Navbar />

      {loading && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && blogs?.blogs?.length == 0 && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <Title>{translate(blogs?.empty_message)}</Title>
        </Stack>
      )}
      {!loading && (
        <Box>
          <div className="container">
            <div class="d-flex flex-row justify-content-center mt-3">
              <div class="p-1">
                {" "}
                <Link to="/">
                  <p className="text-black fw-bold"> {translate("Home")}</p>
                </Link>
              </div>
              <div class="p-1">
                <MdNavigateNext />
              </div>
              <div class="p-1">
                {" "}
                <p
                  style={{
                    color: "#8bc53e",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {translate("Blog")}
                </p>
              </div>
            </div>
            {/* <div ClassName="d-flex flex-row  mt-3">
              <div ClassName="p-1">
                <Link to="/">
                  <p className="text-black fw-bold"> {translate("Home")}</p>
                </Link>
              </div>
              <div ClassName="p-1">
                <MdNavigateNext />
              </div>
              <div ClassName="p-1 ">
                <p style={{
                  color:"#8bc53e",
                  fontSize:"18px",
                  fontWeight:"bold"
                }}>{translate("Blog")}</p>
              </div>
            </div> */}

            <div className="row" style={{ marginTop: "80px" }}>
              {blogs?.blogs?.map((blog, index) => {
                return (
                  <div key={index} className="col-lg-4 mt-4">
                    <Card className="blog-card" sx={{ borderRadius: "10px" }}>
                      <div
                        className="blog-image-section"
                        style={{ backgroundImage: `url(${blog.image})` }}
                      ></div>
                      <CardContent className="">
                        <div className="blog-hotelName-wrapper">
                          <div className="blog-hotelName">
                            {/* Content here */}
                          </div>
                          <div className="blog-hotelName">
                            <p>{blog?.created_at}</p>
                          </div>
                        </div>

                        <div>
                          <div
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="blog-card-content"
                          >
                            <h1 className="same-height">{blog?.title}</h1>
                            <p>{blog?.body}</p>
                          </div>
                        </div>

                        <div className="blog-deatil-button">
                          <Button
                            variant="contained"
                            onClick={() => handleDetails(blog)}
                          >
                            {translate("Read More")}
                            <BsArrowRightShort
                              style={{ fontSize: "15px", marginLeft: "10px" }}
                            />
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </Box>
      )}
      <div style={{ marginTop: "60px" }}>
        {/* <div className="blogPage-fastBoatCards">
          <FastBoatCards />
        </div> */}

        <div className="blogPage-footer">
          <Footer />
        </div>
      </div>
    </Box>
  );
}

export default BlogPage;
