import React, { useCallback, useEffect, useState } from "react";
import "./BlogDetail.css";
import { BsArrowRightShort } from "react-icons/bs";
import Footer from "../../Components/Footer/Footer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Navbar from "../../Components/Navbar/Navbar";
import makeHttpRequest from "../../utils/api";
import { Box, CircularProgress, Stack } from "@mui/material";
import he from "he";
import { useLanguage } from "../../utils/LanguageContext";
import { MdNavigateNext } from "react-icons/md";

import { title } from "../../utils/helpers";

function BlogDetails() {
  const { id, slug } = useParams();
  const navigate = useNavigate();

  const [selectedBlog, setSelectedBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const { translate } = useLanguage();

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `blog/${id}/${slug}`);
      if (res.data.recent_blogs) {
        setRecentBlogs(res.data.recent_blogs);
      }
      if (res.data.related_blogs) {
        setRelatedBlogs(res.data.recent_blogs);
      }
      if (res.data.blog) {
        setSelectedBlog(res.data.blog);
      }
      document.title = title(res?.data?.blog?.name);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  }, [id, slug]);

  useEffect(() => {
    if (id && slug) {
      getData();
    } else {
      navigate("/blog");
    }
  }, [getData, id, slug, navigate]);

  return (
    <>
      <Navbar />
      <Box>
        {loading && (
          <Stack height="100vh" alignItems="center" justifyContent="center">
            <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
          </Stack>
        )}
        {!loading && (
          <>
            <div className="container">
              <div className="blog-detailPage-heading">
                <h1>{translate(selectedBlog?.title)}</h1>
              </div>
              <div class="d-flex flex-row justify-content-center mt-3">
                <div class="p-1">
                  <Link to="/">
                    <p className="text-black fw-bold"> {translate("Home")}</p>
                  </Link>
                </div>
                <div class="p-1">
                  <MdNavigateNext />
                </div>
                <div class="p-1">
                  <Link to="/blog">
                    <p className="text-black fw-bold"> {translate("Blog")}</p>
                  </Link>
                </div>
                <div class="p-1">
                  <MdNavigateNext />
                </div>
                <div class="p-1">
                  <p
                    style={{
                      color: "#8bc53e",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {translate("Blog Detail")}
                  </p>
                </div>
              </div>

              <div className="row" style={{ marginTop: "80PX" }}>
                <div className="col-lg-8 col-md-8 col-12">
                  <div className="blogIndividual-mainImg">
                    <img src={selectedBlog?.image} alt="..." />
                  </div>
                  <div className="blogIndividual-hotelDate">
                    <h6>{/* {selectedBlog?.type} */}</h6>
                    <p>{selectedBlog?.created_at}</p>
                  </div>
                  <div className="blogIndividual-hotelName">
                    <h1>{selectedBlog?.title}</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedBlog?.body
                          ? he.decode(selectedBlog?.body)
                          : "",
                      }}
                    ></div>
                  </div>
                  <hr
                    style={{
                      color: "#FFF",
                      marginTop: "40px",
                      marginBottom: "40px",
                    }}
                  />
                  <hr style={{ color: "#FFF" }} />
                </div>

                <div className="col-lg-4 col-md-4 col-12">
                  <div className="blogIndividual-rightBg mt-4">
                    <div className="blogIndividual-recentPost">
                      <h3 className="fw-bold">{translate("Recent Post")}</h3>
                      <hr />
                      {recentBlogs?.map((blog, index) => (
                        <>
                          <div className="row mb-3 recent-post-section">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <img
                                src={blog?.image}
                                alt={blog?.title}
                                width={120}
                                height={120}
                              />
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                              <h5>{translate(blog?.title)}</h5>
                              {/* <h1>How to Score the Best </h1> */}
                              <p>{blog?.created_at}</p>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="related-blogs-wrapper">
                <div className="blog-detail-headings">
                  <img
                    className="img1"
                    src="/assets/icons/aa1.png"
                    alt="Image1"
                  />
                  <h1>{translate("Related Blog")}</h1>
                  <img
                    className="img2"
                    src="/assets/icons/aa2.jpeg"
                    alt="Image2"
                  />
                </div>
                <div className="related-blogs-button">
                  <button className="view-all-button">View All</button>
                </div>
              </div>

              {loading && (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
                </Stack>
              )}
              {!loading && (
                <div className="row" style={{ marginBottom: "40px" }}>
                  {relatedBlogs?.map((blog, index) => {
                    return (
                      <div
                        key={index}
                        className="col-lg-4 col-md-8 col-sm-12 mt-4"
                      >
                        <Card
                          className="equal-height-card"
                          sx={{ borderRadius: "10px" }}
                        >
                          <div
                            className="blog-detail-image-section"
                            style={{ backgroundImage: `url(${blog.image})` }}
                          ></div>
                          <CardContent>
                            <div className="blog-hotelName-wrapper">
                              <div className="blog-hotelName">
                                <p>
                                  <span>{blog?.type}</span>
                                </p>
                              </div>
                              <div className="blog-hotelName">
                                <p>{blog?.hotelDate}</p>
                              </div>
                            </div>

                            <div>
                              <div
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="blog-card-content"
                              >
                                <h1>{blog?.title}</h1>
                              </div>
                              <div
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="blog-card-content"
                              >
                                <p>{blog?.hotelDiscription}</p>
                                <p>{blog?.hotelDiscription}</p>
                              </div>
                            </div>

                            <div className="blog-deatil-button">
                              <Link>
                                <Button variant="contained">
                                  {translate("Read More")}
                                  <BsArrowRightShort
                                    style={{
                                      fontSize: "15px",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </Button>
                              </Link>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </Box>
      {!loading && (
        <>
          <div className="blogIndividual-footer">
            <Footer />
          </div>
        </>
      )}
    </>
  );
}

export default BlogDetails;
