import React, { useState} from 'react';
import { BiPaperclip, BiSmile } from 'react-icons/bi';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Link } from 'react-router-dom';
import makeHttpRequest from '../../../../utils/api';

function InputMessage  ({item,id,inputState,inputIframeState,sidebar,selectedUser,setUserSentMessage,setAttachedFile,chats,setChats,classes}) {
    
    
    const {inputText, setInputText} = inputState;
    const {inputIframe, setInputIframe} = inputIframeState;
    const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State to manage emoji picker visibility

    const handleSendMessage = async (text) => {
        if (!selectedUser) return;
        
        try {
          let text_data = {
            item:item,
            id,
            user:selectedUser,
            content:text
          };
          if(inputIframe){
            text_data.meta = { 'iframe':inputIframe};
          } 
          const res = await makeHttpRequest("POST", `user/chat/message/store`, text_data);
          
          if(res.success){
            setShowEmojiPicker(false);
            setInputIframe('');
            // setConversations(res?.data?.chat);
            // setSelectedUser(res?.data?.chat[0]);
          } else if(!res.success){
          } else{
          }
        } catch (error) {
          console.error("Error is:", error);
        }
    
        // setChats(prevMessages => ({
        //   ...prevMessages,
        //   [selectedUser.id]: [...(prevMessage || []), newMessage]
        // }));
        setUserSentMessage(true); // Set the flag when the user sends a message
        setInputText('');
      };

      const handleInputChange = (e) => {
        setInputText(e.target.value);
      };
    
      const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setAttachedFile(file);
      };
    
      const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
      };
    
      const handleEmojiSelect = (emoji) => {
        setInputText(inputText + emoji.native); // Insert the selected emoji into the input text
      };

    return (<div className={sidebar ? `${classes['chat-input']} ${classes['inputIndex']}` : `${classes['chat-input']}`}>
        {/* {inputIframe && (
                <div 
                className={classes['custom-offer-iframe']}
                // scr={inputIframe} 
                frameBorder="0"
                allowFullScreen
                title={inputIframe}>
                  <p>
                    <Link target='_blank' to={inputIframe}>{inputIframe}</Link>
                  </p>
                </div>
                )} */}
                  <input
                    type="text"
                    placeholder="Type a message..."
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSendMessage(inputText);
                        setInputText('');
                      }
                    }}
                    value={inputText}
                    disabled={!selectedUser} // Disable input if no user selected
                    onChange={handleInputChange}
                  />
                  <div className={classes['buttons-container']}>
                    <div className={classes['buttons-SubContainer']}> 
                      {/* emoji button  */}
                      {/* <i className="fa fa-smile-o fa-lg" onClick={toggleEmojiPicker}></i> */}
                      <BiSmile onClick={toggleEmojiPicker}/>
                      {showEmojiPicker && (
                        <div className={classes['emoji-container']}>
                        <Picker 
                        data={data}
                        previewPosition="none"
                        onEmojiSelect={handleEmojiSelect} />
                        </div>
                      )}
                      {/* attachment button  */}
                      {/* <label htmlFor="fileInput" className={classes['attachment-icon']}>
                        <BiPaperclip />
                      </label>
                      <input
                        id="fileInput"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                      /> */}
                    </div>
                    {/* send button  */}
                    <button className={classes['send-button']} onClick={() => handleSendMessage(inputText)} disabled={!selectedUser}>Send</button>
                  </div>  
                </div>);
}
export default InputMessage;