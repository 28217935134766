import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import DashboardLogout from "../../DashboardComponents/DashboardLogout/DashboardLogout";
import { useLanguage } from "../../utils/LanguageContext";
import { Link } from "react-router-dom";
import { BiChat } from "react-icons/bi";
import { useState } from "react";
import { useEffect } from "react";
import makeHttpRequest from "../../utils/api";

const AvatarImg = () => {
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  );
  const [languages, setLanguages] = useState("");
  const [loading, setLoading] = useState(false);
  const handleLanguage = (newLanguage) => {
    setSelectedLanguage(newLanguage);
    fetchLangData(newLanguage);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "language");
      setLanguages(res?.data?.languages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const { langData, fetchLangData, translate } = useLanguage();
  // https://codesandbox.io/s/dropdown-avatar-material-qgh0p9?file=/avatar.tsx:0-3786

  const [avatarEl, setAvatarEl] = React.useState(null);

  const [invisible, setInvisible] = React.useState(false);

  const handleAvatarClick = (e) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const [notifyEl, setNotifyEl] = React.useState(null);

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
  };

  const handleNotifyOpen = (e) => {
    setNotifyEl(e.currentTarget);
    if (!invisible) {
      handleBadgeVisibility();
    }
  };

  const handleNotifyClose = () => {
    setNotifyEl(null);
  };

  const open = Boolean(avatarEl);
  const id = open ? "simpe-popover" : undefined;

  const notifyOpen = Boolean(notifyEl);
  const notifyId = notifyOpen ? "simpe-notify" : undefined;

  return (
    <div>
      <div className="nav-contant"></div>
      <Stack direction="row" spacing={1}>
        <div className="language-dropdown mt-3 pt-1">
          <select
            style={{ color: "black" }}
            id="language-select"
            value={selectedLanguage}
            onChange={(e) => handleLanguage(e.target.value)}
          >
            {languages &&
              languages?.map((lan, i) => (
                <option key={lan?.code} value={lan?.code}>
                  {lan?.name}
                </option>
              ))}
          </select>
        </div>
        <Button>
          {/* <Badge
            color="warning"
            overlap="circular"
            badgeContent="1"
            invisible={invisible}
            showZero={true}
          > */}
          <Link to={"/chat"}>
            <BiChat style={{ fontSize: "30px", color: "#000" }} />
          </Link>
          {/* </Badge> */}
        </Button>
        {/* <Button aria-describedby={id} onClick={handleNotifyOpen}>
          <Badge
            color="warning"
            overlap="circular"
            badgeContent="1"
            invisible={invisible}
            showZero={true}
          >
            <NotificationsIcon />
          </Badge>
        </Button> */}
        <Button aria-describedby={id} onClick={handleAvatarClick}>
          {/* <Avatar></Avatar> */}
          <img src="/assets/icons/iconUser.png" alt="..." />
          {/* <KeyboardArrowDownIcon /> */}
        </Button>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={avatarEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List disablePadding>
          {/* <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Avatar" />
            </ListItemButton>
          </ListItem>
          <Divider /> */}
          {/* <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Favorites" />
            </ListItemButton>
          </ListItem> */}
          {/* <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Setting" />
            </ListItemButton>
          </ListItem>
          <Divider /> */}
          <ListItem className="px-3">
            <ListItemButton>
              <Link
                to={"/profile"}
                style={{ fontSize: "20px", textDecoration: "none" }}
              >
                {translate("Profile")}
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem className="px-3">
            <ListItemButton>
              {/* <ListItemText primary="Log out" /> */}
              <DashboardLogout />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      <Popover
        id={notifyId}
        open={notifyOpen}
        anchorEl={notifyEl}
        onClose={handleNotifyClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Avatar" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Favorites" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default AvatarImg;
