import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import makeHttpRequest from "../utils/api";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

function CustomLeftArrow({ onClick }) {
  return (
    <button className="custom-arrow custom-arrow-prev" onClick={onClick}>
      <BsArrowLeft />
    </button>
  );
}

function CustomRightArrow({ onClick }) {
  return (
    <button className="custom-arrow" onClick={onClick}>
      <BsArrowRight />
    </button>
  );
}

function RegisterImage({ type }) {
  const [content, setContent] = useState([]);
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const res = await makeHttpRequest("POST", "content", {
          content: type,
        });

        setHeading(res?.data?.content?.heading);
        setSubHeading(res?.data?.content?.sub_heading);

        setContent([
          {
            image: "/assets/icons/loginPageImg.png",
          },
          {
            image: "/assets/icons/signupPageImg.png",
          },
          {
            image: "/assets/icons/resetPasswordImg.png",
          },
        ]);
      } catch (error) {
        console.error("Error is:", error);
      }
    };
    fetchCountryData();
  }, [type]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Carousel
        autoPlay={false}
        autoPlaySpeed={3000}
        infinite={true}
        responsive={responsive}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {content?.map((item, index) => (
          <div key={index}>
            <div
              className="carousel-image"
              style={{
                backgroundImage: `url(${item?.image})`,
              }}
            >
              <div className="carousel-text">
                <p>{subHeading}</p>
                <h3>{heading}</h3>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}

export default RegisterImage;
