import React from "react";
import DiscoverBtn from "./DiscoverBtn";
import "./Style.css";
import { useLanguage } from "../../utils/LanguageContext";

function LeftHeading({ h1, p, btn_label, img1, img2 }) {
  const { langData, fetchLangData, translate } = useLanguage();

  return (
    <>
      <div className="home-carousel-heading">
        <h2> {translate(h1)}</h2>
      </div>
    </>
    // <div className="left">
    //   <div className="left-heading">
    //     <div className="lh-main-heading">
    //       <h1 className="h1">
    //         {translate(h1)}
    //         {!img1 && (
    //         <img className="img1" src="/assets/icons/aa2.jpeg" alt="..." />
    //         )}
    //         {!img2 && (
    //         <img className="img2" src="/assets/icons/aa1.png" alt="..." />
    //         )}
    //       </h1>
    //     </div>
    //     <p className="mt-2">{translate(p)}</p>
    //   </div>
    //   {btn_label && (
    //     <DiscoverBtn label={btn_label} url={"locations"} position={"start"} />
    //   )}
    // </div>
  );
}

export default LeftHeading;
