import React from "react";
import "./Style.css";

function LeftHeading({ h1, p }) {
  return (
    <>
      <div className="">
        <div className="travel-experience">
          <img className="" src="/assets/icons/aa1.png" alt="Image" />
          <h1>{h1}</h1>
          <img className="img2" src="/assets/icons/aa2.jpeg" alt="Image" />
        </div>
      </div>
      <p className="text-center fs-5">{p}</p>
    </>
  );
}

export default LeftHeading;
