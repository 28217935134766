import React from "react";
import "./BoatDetail.css";
import Navbar from "../../Components/Navbar/Navbar";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsStarFill, BsFillHeartFill } from "react-icons/bs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { BsFillStarFill } from "react-icons/bs";
import Footer from "../../Components/Footer/Footer";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import { CircularProgress, Stack } from "@mui/material";
import { showAmount } from "../../utils/showAmount";
import { useRef, useState } from "react";
import { useEffect } from "react";
import makeHttpRequest from "../../utils/api";
import { BsArrowRightShort, BsShare } from "react-icons/bs";
import { BiCheck, BiChevronsRight } from "react-icons/bi";
import he from "he";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { MdClose } from "react-icons/md";
import Toaster from "../../Components/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import SocialShare from "../../Components/SocialShare";
import Wishlist from "../../Components/Wishlist";
import { useLanguage } from "../../utils/LanguageContext";
import DetailCarousal from "../../Components/LocationCarousal/DetailCarousal";
import { generateSlug, title, roundTowardsMax } from "../../utils/helpers";

const style = {
  // position: "absolute",
  // top: "40%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
  borderRadius: "16px",
  border: "none",
  outline: "none",
};

function BoatDetail() {
  const navigate = useNavigate();
  const { id, slug } = useParams();
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const { langData, fetchLangData, translate } = useLanguage();
  const { isLoggedIn } = useSelector((state) => state.user);

  const [images, setImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [show, setShow] = useState(false);

  const fetchImages = async () => {
    try {
      const response = await makeHttpRequest("POST", "images", {
        id,
        type: "boat",
      });
      console.log("API Response:", response.data);
      setImages(response?.data?.images);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleShow = () => {
    setShow(true);
    fetchImages();
  };

  const handleCarouselChange = (index) => {
    setActiveIndex(index);
  };

  // Calendar Variable
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [days, setDays] = useState(0);
  const [platform_fee, setPlatformFee] = useState(0);

  const handleCheckInChange = (event) => {
    setCheckInDate(event.target.value);
    calculateDateDifference(event.target.value, checkOutDate);
  };

  const handleCheckOutChange = (event) => {
    setCheckOutDate(event.target.value);
    calculateDateDifference(checkInDate, event.target.value);
  };

  const calculateDateDifference = (checkIn, checkOut) => {
    if (checkIn && checkOut) {
      const checkInDateObj = new Date(checkIn);
      const checkOutDateObj = new Date(checkOut);

      if (checkOutDateObj < checkInDateObj) {
        alert("Check-out date must be later than check-in date");
        setDays(0);
        setCheckOutDate(checkIn + 1);
      } else {
        const differenceInMilliseconds = checkOutDateObj - checkInDateObj;
        let differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        // alert(checkOutDateObj == checkInDateObj)
        if (
          checkOutDateObj.getFullYear() === checkInDateObj.getFullYear() &&
          checkOutDateObj.getMonth() === checkInDateObj.getMonth() &&
          checkOutDateObj.getDate() === checkInDateObj.getDate()
        ) {
          setDays((differenceInDays = 1));
        } else {
          setDays(differenceInDays + 1);
        }
      }
    } else {
      setDays(0);
    }
  };

  const handleOpen = async (event) => {
    setOpen(true);
  };
  // const handleClose = () => setOpen(false);
  const handleClose = () => {
    setShow(false);
    setOpen(false);
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [slug]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `boat/${id}/${slug}`);
      if (res.success) {
        setDetails(res.data);
        console.log(details?.boat?.owner_review);
        document.title = title(res?.data?.boat?.name);
        setLoading(false);
      } else if (!res.success) {
        navigate("/boat");
      } else {
        navigate("/boat");
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async () => {
    try {
      if (!state.username) {
        Toaster("info", "Enter username");
        return;
      }
      if (!state.password) {
        Toaster("info", "Enter password");
        return;
      }
      setLoading(true);
      const data = {
        username: state.username,
        password: state.password,
        user_type: "user",
      };
      const res = await makeHttpRequest("POST", "login", data);

      setLoading(false);
      if (res.success === true) {
        localStorage.setItem("bearerToken", res.data.token);
        localStorage.setItem("userType", res.data.type);
        localStorage.setItem("userEmail", res.data.user.email);

        Toaster("success", res.message);
        dispatch(userLogged("user"));
        window.reload();
      } else {
        Toaster("error", "Invalid credentials");
      }
    } catch (error) {
      console.error("Error is :", error);
      setLoading(false);
    }
  };

  const handleEnter = (name) => {
    if (name == "username") {
      if (!state.username) {
        Toaster("info", "Enter email");
      } else if (state.username && !state.password) {
        passwordRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    } else {
      if (!state.password) {
        Toaster("info", "Enter password");
      } else if (!state.username && state.password) {
        usernameRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    }
  };

  const handleInquiry = async (req) => {
    if (checkInDate && checkOutDate) {
      try {
        setLoading(true);
        if (req === "inquiry") {
          const res = await makeHttpRequest("POST", `user/boat/booking`, {
            boat_list: id,
            start: checkInDate,
            end: checkOutDate,
            days: days,
          });
          Toaster("success", res.message);
        } else {
          const res = await makeHttpRequest("POST", `user/chat/message/store`, {
            slug,
            id,
            type: "item",
            item: "boat",
            start: checkInDate,
            end: checkOutDate,
            days: days,
            url: window.location.href,
          });
          if (res.success) {
            navigate(`/chat/boat/${id}/${slug}`);
          } else {
            Toaster("error", "Something went wrong");
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error is:", error);
        setLoading(false);
      }
    } else {
      Toaster("error", "Select Check in and Check out date");
    }
  };

  // const handleOwnerChat = () =>{
  //   try {
  //     setLoading(true);
  //     // let slug = generateSlug(details?.boat?.owner?.username)
  //     navigate(`/chat/boat/${id}/${slug}`);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error is:", error);
  //     setLoading(false);
  //   }
  // }

  return (
    <>
      <Navbar />
      {loading && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && details && details?.boat?.length === 0 && (
        <Stack my={5} alignItems="center" justifyContent="center">
          <h2>{translate(details?.empty_message)}</h2>
        </Stack>
      )}
      {!loading && (
        <div className="container">
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="modal-wrapper">
              <Box sx={style}>
                <div className="modal-heading">
                  <h1>{translate("Login")}</h1>
                  <MdClose
                    onClick={handleClose}
                    style={{
                      color: "#FFF",
                      fontSize: "27px",
                      cursor: "pointer",
                      marginRight: "30px",
                    }}
                  />
                </div>
                <div className="modal-inputs">
                  <div className="scrollable-content">
                    <div className="form-group">
                      <label>{translate("User name")}</label>
                      <input
                        type="username"
                        name="username"
                        className="form-control-sm"
                        placeholder="Enter Username"
                        ref={usernameRef}
                        value={state.username}
                        onChange={handleInput}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            handleEnter("username");
                          }
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>{translate("Password")}</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control-sm"
                        placeholder="Enter your password"
                        ref={passwordRef}
                        value={state.password}
                        onChange={handleInput}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            handleEnter("password");
                          }
                        }}
                      />
                    </div>
                    <div className="mt-1">
                      <button className="btn btn-primary" onClick={handleLogin}>
                        {translate("Login")}
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </Modal>

          <div className="row mt-5">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <DetailCarousal images={details?.boat?.images} />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="boatDetail-right-bg">
                <div>
                  <div className="boatDetail-ranking-wrapper">
                    <div className="boatDetail-ranking">
                      <h3>
                        {details?.curr_sym} {showAmount(details?.boat?.price)}{" "}
                        <span>{translate(details?.boat?.label)}</span>
                      </h3>
                    </div>
                    <div className="boatDetail-reviews">
                      <p>
                        <BsFillStarFill
                          style={{
                            color: "#8BC53E",
                            marginRight: "7px",
                            fontSize: "15px",
                          }}
                        />
                        <span>
                          <a href="#">
                            {showAmount(details?.boat?.rating)} (
                            {details?.boat?.review}) {translate("Reviews")}
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 mt-3">
                      <div className="boatDetail-date-wrapper">
                        {/* <img src="/assets/icons/Calendar.png" alt="Check in calendar" /> &nbsp; */}
                        <div className="boatDate-content">
                          <label
                            for="check-in"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <img
                              src="/assets/icons/Calendar.png"
                              alt="Check in calendar"
                            />
                            {translate("Check In")}
                          </label>
                          <input
                            type="datetime-local"
                            placeholder={translate("Check In")}
                            value={checkInDate}
                            onChange={handleCheckInChange}
                            min={new Date().toISOString().split("T")[0]}
                            id="check-in"
                          />
                          {/* <h2>Check-In</h2>
                          <p>22/07/2023</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt-3 ">
                      <div className="boatDetail-date-wrapper">
                        {/* <img src="/assets/icons/Calendar.png" alt="Check out calendar" />&nbsp; */}
                        <div className="boatDate-content">
                          <label
                            for="check-out"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <img
                              src="/assets/icons/Calendar.png"
                              alt="Check out calendar"
                            />
                            {translate("Check Out")}
                          </label>
                          <input
                            type="datetime-local"
                            placeholder={translate("Check Out")}
                            value={checkOutDate}
                            onChange={handleCheckOutChange}
                            min={(function () {
                              const tomorrow = new Date();
                              tomorrow.setDate(tomorrow.getDate() + 1);
                              return tomorrow.toISOString().split("T")[0];
                            })()}
                            id="check-out"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 mt-3 ">
                      <div className="boatDetail-date-wrapper">
                        <img src="/assets/icons/guestIcon.png" alt="....." />
                        <div className="boatDate-content">
                          <h2>Guest</h2>
                          <p>02 Guest</p>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="boatRent-perNight mt-3 p-0">
                    <p>
                      {details?.curr_sym}
                      {showAmount(details?.boat?.price)} x {days}
                    </p>
                    <p>
                      {details?.curr_sym}
                      {showAmount(
                        parseInt(details?.boat?.price) * parseInt(days)
                      )}
                    </p>
                  </div>
                  <div className="accomodationRent-perNight mt-2 p-0 ">
                    <p>{translate("Discount")}</p>
                    <p>
                      {showAmount(details?.boat?.discount, 0)}
                      {translate("%")}
                    </p>
                  </div>
                  {/* <div className="boatRent-perNight mt-2 p-0 ">
                    <p>{translate("Capri Easy Service")}</p>
                    <p>
                      {translate(details?.curr_sym)}
                      {platform_fee}
                    </p>
                  </div> */}
                  <hr />
                  <div className="boatRent-perNight-total mt-2 p-0 ">
                    <p>{translate("Total")}</p>
                    <p>
                      {details?.curr_sym}
                      {showAmount(
                        details?.boat?.discount > 0
                          ? parseInt(
                              details?.boat?.price -
                                (details?.boat?.price *
                                  details?.boat?.discount) /
                                  100
                            ) *
                              parseInt(days) +
                              parseInt(platform_fee)
                          : parseInt(details?.boat?.price) * parseInt(days) +
                              parseInt(platform_fee),
                        0
                      )}
                    </p>
                  </div>

                  <div className="boatDetail-reserver">
                    {/* <Link to="/boatPage/bookingInfo"> */}
                    {localStorage.getItem("bearerToken") && (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleInquiry("offer")}
                      >
                        {translate("Wanna Custom Offer, Contact")}
                      </button>
                    )}

                    {/* {localStorage.getItem("bearerToken") && (
                      <button onClick={()=>handleInquiry('inquiry')}>
                        {translate("Send Inquiry")}
                      </button>
                    )} */}
                    {!localStorage.getItem("bearerToken") && (
                      <button onClick={handleOpen}>
                        {translate("Wanna Custom Offer, Contact")}
                      </button>
                    )}
                    {/* {!localStorage.getItem("bearerToken") && (
                      <button onClick={handleOpen}>
                        {translate("Send Inquiry")}
                      </button>
                    )} */}
                    {/* </Link> */}
                    <p>{translate("You won’t be charged yet")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* new slider */}
          <div className="mt-5"></div>

          <div className="row mt-5">
            <div className="col-lg-8">
              <div className=" boatDetail-left-bg">
                <div className="boatDetail-location-wrapper">
                  <h3 className="boatDetail-location">
                    {translate(details?.boat?.name)}{" "}
                  </h3>
                  <div className="boat-wishlist-wrapper">
                    <Wishlist
                      id={details?.boat?.id}
                      type={"boat"}
                      style={{
                        padding: "3px 6px 0px 6px",
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                </div>
                <div className="boatDetail-location-icon">
                  <p>
                    <FaMapMarkerAlt
                      style={{
                        color: "#8BC53E",
                        marginRight: "7px",
                        fontSize: "15px",
                      }}
                    />
                    <span>{translate(details?.boat?.location.name)}</span>
                  </p>
                </div>
                <div className="things-to-know-section">
                  {details?.boat?.extra_features?.length && (
                    <h1 className="boatThings-heading">
                      {translate("Things to know")}
                    </h1>
                  )}
                  {details?.boat?.extra_features?.map((feature, i) => (
                    <div key={i} className="boatThings-content-wrapper">
                      <BiChevronsRight className="boatThings-icon" />
                      <div className="boatThings-content">
                        <h2>{translate(feature)}</h2>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className=" boatDetail-left-bg mt-5">
                <h1 className="boatDetail-amenities-heading mb-3">
                  {translate("Amenities")}
                </h1>
                <div className="things-to-know-section">
                  {details?.boat?.extra_features?.length && (
                    <h1 className="boatThings-heading">
                      {translate("Things to know")}
                    </h1>
                  )}
                  {details?.boat?.amenities?.map((am, i) => (
                    <div key={i} className="boatThings-content-wrapper">
                      <BiCheck style={{ fontSize: "20px", color:"8bc53e" }}  className="boatThings-icon" />
                      <div className="boatThings-content">
                        <h2>{translate(am?.name)}</h2>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="row">
                  {details?.boat?.amenities?.map((am, i) => (
                    <div className="col-lg-6 col-md-6 col-12 pl-0 mt-2 boatAmenities--feature-wrapper">
                      <i className="las la-music"></i>
                      <div className="boatAmenities--feature">
                        <p title={am?.name}>
                          <BiCheck style={{ fontSize: "20px" }} /> {am?.name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>
              <div className=" boatDetail-left-bg mt-5">
                <h1 className="boatDetail-amenities-heading mb-3">
                  {translate("Discription")}
                </h1>
                <div
                  className="boatDetail-discription"
                  dangerouslySetInnerHTML={{
                    __html: details?.boat?.description
                      ? he.decode(details.boat.description)
                      : "",
                  }}
                ></div>
              </div>
              <div className="boatDetail-ocation mt-5">
                <div className="row">
                  <h1 className="boatDetail-amenities-heading mb-3">
                    {translate("Location")}
                  </h1>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="mapouter w-100">
                      <div className="gmap_canvas">
                        <iframe
                          title="Location of your boat"
                          id="gmap_canvas"
                          // src="https://maps.google.com/maps?q=Washington+Avenue+Southeast,+Manchester/&t=&z=13&ie=UTF8&iwloc=&output=embed"
                          src={`${details?.boat?.map_url}=UTF8&iwloc=&output=embed`}
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="/assets/icons/boatDetailLocation.png" alt="....." /> */}
              </div>

              {/* HOST */}
              <div className="boatDetail-left-bg mt-5">
                <h1 className="boatDetail-amenities-heading mb-3">
                  {translate("Host Information")}
                </h1>
                <div className="col-12 pl-0 mt-4 ">
                  <div className="host-name-wrapper">
                    <img
                      src="/assets/icons/avatar.png"
                      alt="....."
                      className="border rounded-circle"
                    />
                    <div className="host-name">
                      <h2>
                        {details?.boat?.owner?.firstname}{" "}
                        {details?.boat?.owner?.lastname}
                      </h2>
                      <p>
                        <BsStarFill style={{ color: "yellow" }} />
                        <span>
                          {showAmount(details?.boat?.owner_rating)} (
                          {details?.boat?.owner_review}) {translate("Review")}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="host-discription">
                    <p>{details?.boat?.owner?.bio}</p>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 boatHost-feature-wrapper">
                    <img src="/assets/icons/Calendar.png" alt="....." />
                    <div className="boatAmenities--feature">
                      <p>
                        {translate("Join in")}{" "}
                        {new Date(
                          details?.boat?.owner?.created_at
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 boatHost-feature-wrapper">
                    <img src="/assets/icons/RankingRate.png" alt="....." />
                    <div className="boatAmenities--feature">
                      <p>{translate("Response Rate 100%")}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 boatHost-feature-wrapper">
                    <img src="/assets/icons/Bookmark.png" alt="....." />
                    <div className="boatHost-feature">
                      <p>{translate("Fast response - within a few hours")}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* REVIEWS */}
              <div className="mt-5">
                <h1 className="accomodation-reviewHeading">
                  {translate("Reviews")}
                  <span style={{ fontSize: "25px", fontWeight: "700" }}>
                    ({details?.boat?.reviews?.length})
                  </span>
                </h1>
                <div className="row p-1">
                  {details?.boat?.reviews?.length > 0 &&
                    details?.boat?.reviews?.map((re, index) => (
                      <div
                        key={index}
                        className="col-lg-12 col-md-6 d-none d-lg-block d-md-block my-1"
                      >
                        <div className="reviews-wrapper">
                          <div className="Accomodationhost-name-wrapper">
                            <img
                              src="/assets/icons/avatar.png"
                              alt="....."
                              className="border rounded-circle"
                            />
                            <div className="ml-4">
                              <div className="accomodation-reviewStars mb-2">
                                {Array.from(
                                  { length: re?.rating },
                                  (_, starIndex) => (
                                    <BsStarFill
                                      key={starIndex}
                                      style={{ color: "yellow" }}
                                    />
                                  )
                                )}
                              </div>
                              <div className="client-review">
                                <h2>
                                  {re?.user?.firstname} {re?.user?.lastname}
                                </h2>
                                <p>
                                  {re?.created_at &&
                                    new Date(re?.created_at).toLocaleDateString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      }
                                    )}
                                </p>
                              </div>

                              <p className="review-discription">
                                {re?.description}
                              </p>
                            </div>
                          </div>
                          <div className="reply-reviews">
                            <h2>{translate("Login to reply")}</h2>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="col-lg-12 col-md-6 d-none d-lg-block d-md-block mt-3">
                    <div className=" addReviview-wrapper">
                      <div className="add-review">
                        <h1>{translate("Add a review")}</h1>
                        {!isLoggedIn && (
                          <p>
                            {translate(
                              "You must be logged in to post a comment."
                            )}
                          </p>
                        )}
                      </div>
                      {isLoggedIn && (
                        <div className="older-comment-btn">
                          <Link to="/user-reviews">
                            <Button variant="contained">
                              {translate("My Reviews")}
                              <BsArrowRightShort
                                style={{ fontSize: "18px", marginLeft: "10px" }}
                              />
                            </Button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4"></div>
          </div>

          <div className="similar-boat-heading">
            <h1>{translate("Similar Boats")}</h1>
          </div>
          <div className="row" style={{ marginBottom: "100px" }}>
            {/* <div className="row class1 mb-5"> */}
            {details?.boats?.map((d, index) => {
              return (
                <div className="col-lg-4 mt-4">
                  <Card>
                    <CardMedia>
                      <div
                        id={`carouselExampleIndicators_${index}`}
                        className="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-indicators">
                          {d.image.map((image, i) => (
                            <button
                              type="button"
                              data-bs-target={`#carouselExampleIndicators_${index}`}
                              data-bs-slide-to={i}
                              className={i === 0 ? "active" : ""}
                              aria-current={i === 0 ? "true" : "false"}
                              key={i}
                            ></button>
                          ))}
                        </div>
                        <div className="carousel-inner">
                          {d.image.map((image, i) => (
                            <div
                              className={`carousel-item ${
                                i === 0 ? "active" : ""
                              } boat-detail-carouser-div`}
                              key={i}
                            >
                              <img
                                src={image}
                                className="d-block w-100"
                                alt={`Slide ${i + 1}`}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="boat-card-offer">
                          {d?.discount > 0 && (
                            <h4>
                              {d?.discount} {translate("% off")}
                            </h4>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "20px",
                              color: "#FFF",
                              background: "#8BC53E",
                              padding: "4px 7px",
                              borderRadius: "50px",
                            }}
                          >
                            <BsFillHeartFill />
                          </div>
                        </div>
                      </div>
                    </CardMedia>
                    <CardContent className="">
                      <div className="shipList-ranking">
                        <Typography className="shiplist-card-location">
                          <p>
                            <FaMapMarkerAlt
                              style={{
                                color: "#8BC53E",
                                marginRight: "7px",
                                fontSize: "15px",
                              }}
                            />
                            <span>{d.location.name}</span>
                          </p>
                        </Typography>
                        <Typography className="shiplist-card-location">
                          <p>
                            <BsFillStarFill
                              style={{
                                color: "yellow",
                                marginRight: "7px",
                                fontSize: "15px",
                              }}
                            />
                            <span>{showAmount(d.rating)}</span>
                          </p>
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="shiplist-card-heading"
                        >
                          <h1>{d.name}</h1>
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="shiplist-card-discription"
                        >
                          {/* <p>{d.guests} Guests</p>
                          <p>{d.bed} Bed</p>
                          <p>{d.cabin} Cabin</p>
                          <p>{d.bathroom} bathroom</p> */}
                        </Typography>
                      </div>

                      <Typography className="shiplist-card-deatil-button">
                        <h3>
                          {details?.curr_sym}
                          {showAmount(d.price)} <span>{d.label}</span>
                        </h3>
                        <Link to={`/boat/${d?.id}/${d?.slug}/details`}>
                          <button>{translate("View Detail")}</button>
                        </Link>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
            {/* </div> */}
          </div>
        </div>
      )}

      {!loading && (
        <div className="boatDetailPage-footer">
          <Footer />
        </div>
      )}
    </>
  );
}

export default BoatDetail;
