import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import makeHttpRequest from "../utils/api";

function ExtensionApi({ CaptchaPassed }) {
  const [extension, setExtension] = useState({});

  const handleRecaptchaChange = (value) => {
    CaptchaPassed(Boolean(value));
  };

  useEffect(() => {
    const checkExtension = async () => {
      try {
        const res = await makeHttpRequest("GET", "extension");
        setExtension(res.data);
      } catch (error) {
        console.error("Error fetching extension data:", error);
        CaptchaPassed(false);
      }
    };
    checkExtension();
  }, [CaptchaPassed]);

  return (
    <>
      {extension.googlerecaptcha2?.status === 1 ? (
        <ReCAPTCHA
          sitekey={extension.googlerecaptcha2?.shortcode?.sitekey?.value}
          onChange={handleRecaptchaChange}
        />
      ) : (
        CaptchaPassed(false)
      )}
    </>
  );
}

export default ExtensionApi;
