import React, { useState, useEffect, useRef } from "react";
import { MessageList, Avatar } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./OwnerChat.module.css";
import "../ChatStyle.css";
import InputMessage from "./component/InputMessage";
import { useParams, Link } from "react-router-dom";
import makeHttpRequest from "../../../utils/api";
import { useLanguage } from "../../../utils/LanguageContext";
import { CircularProgress, Stack } from "@mui/material";
import ChatDisplayDate from "./component/ChatDisplayDate";
import { isDateExpired, pusherHelper, ucwords } from "../../../utils/helpers";
import { showAmount } from "../../../utils/showAmount";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Dropdown } from "react-bootstrap";
import NickName from "./component/NickName";
import Note from "./component/Note";

const Chat = () => {
  const { type, id, slug } = useParams();
  const [inputText, setInputText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [userSentMessage, setUserSentMessage] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();
  const [attachedFile, setAttachedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading_chat, setLoadingChat] = useState(false);
  const [conversations, setConversations] = useState();
  const [chats, setChats] = useState([]);
  const [logged_id, setLoggedIn] = useState();
  const [scroll_below, setScrollBelow] = useState();
  const [messages_limit, setMessagesLimit] = useState(0);

  // pass currency to child
  const [currency, setCurrency] = useState();
  const [edit_chat, setEditChat] = useState();
  const [already_value, setAlreadyValue] = useState();

  // Trigger modal from here
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const openOfferModal = () => {
    setIsOfferModalOpen(true);
  };

  // Show NickName Model
  const [showNickNameModal, setShowNickNameModal] = useState(false);
  const handleCloseNickNameModal = () => setShowNickNameModal(false);
  const handleShowNickNameModal = (
    edit_chat_person_id,
    par_already_value = null
  ) => {
    setShowNickNameModal(true);
    setEditChat(edit_chat_person_id);
    setAlreadyValue(par_already_value);
  };

  // Show Note Model
  const [showNoteModal, setShowNoteModal] = useState(false);
  const handleCloseNoteModal = () => setShowNoteModal(false);
  const handleShowNoteModal = (
    edit_chat_person_id,
    par_already_value = null
  ) => {
    setShowNoteModal(true);
    setEditChat(edit_chat_person_id);
    setAlreadyValue(par_already_value);
  };

  // Delete Conversation
  async function handleDeleteConversation(id) {
    const res = await makeHttpRequest("POST", `owner/chat/delete`, { id });
    if (res?.success) {
      await getConversation(id, slug);
      setChats([]);
    } else {
      console.log(res?.message);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getConversation(id, slug);
    };

    fetchData();

    window.Pusher = Pusher;
    const echo = new Echo({
      broadcaster: "pusher",
      key: pusherHelper("api_key"),
      cluster: pusherHelper("cluster"),
      forceTLS: true,
    });

    echo.channel("chat-channel").listen(".chat-event", (e) => {
      setChats((prevMessages) => [...prevMessages, e.message]);
    });
  }, []);

  async function getConversation(id, slug) {
    try {
      setLoadingChat(true);
      const res = await makeHttpRequest("POST", `owner/chat/conversations`);
      if (res.success) {
        setConversations(res?.data?.chat);
        setSelectedUser(res?.data?.chat[0]);
        handleMessageClick(res?.data?.chat[0]);
        setCurrency(res?.data?.curr_sym);
        setLoadingChat(false);
      } else if (!res.success) {
      } else {
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoadingChat(false);
    }
  }

  useEffect(() => {
    const fetchData = () => {
      document
        .querySelector("#chat-messages")
        .scrollTo(0, document.body.scrollHeight);
    };

    fetchData();
  }, [scroll_below]);
  const handleMessageClick = async (user, is_increment = true) => {
    setSelectedUser(user);
    if (user && user?.conversation_id !== 0) {
      try {
        setLoading(true);
        const res = await makeHttpRequest("POST", `owner/chat/messages`, {
          slug,
          id,
          user,
          limit: messages_limit,
        });
        if (res.success) {
          setScrollBelow(user);
          if (is_increment) setMessagesLimit(res?.data?.count);
          setChats(res?.data?.messages);
          setLoggedIn(res?.data?.logged_id);
        } else if (!res.success) {
        } else {
        }
      } catch (error) {
        console.error("Error is:", error);
        setLoading(false);
      }
    }
    setSidebar(false);
  };

  const handleHamburgerClick = () => {
    setSidebar(true);
  };

  const backdropHandle = () => {
    setSidebar(false);
  };

  function loadMoreMessages() {
    setMessagesLimit(messages_limit + 20);
    handleMessageClick(selectedUser);
  }

  return loading_chat ? (
    <Stack height="80vh" alignItems="center" justifyContent="center">
      <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
    </Stack>
  ) : (
    <div className={classes["chat-container"]}>
      <div
        className={
          sidebar
            ? `${classes["sidebar"]} ${classes["sidebarMobile"]}`
            : classes["sidebar"]
        }
      >
        <h4>{translate("Messages")}</h4>
        <hr />
        <NickName
          show={showNickNameModal}
          handleClose={handleCloseNickNameModal}
          handleShow={handleShowNickNameModal}
          chat_person_id={edit_chat}
        />
        <Note
          show={showNoteModal}
          handleClose={handleCloseNoteModal}
          handleShow={handleShowNoteModal}
          chat_person_id={edit_chat}
          note_content={already_value}
        />
        <ul className={classes["chat-ul"]}>
          {conversations?.map((conversation) => (
            <li
              key={conversation.id}
              className={`d-flex justify-content-between ${classes["userId"]} ${
                selectedUser && selectedUser.id === conversation.id
                  ? classes["selected"]
                  : ""
              }`}
              onClick={() => handleMessageClick(conversation)}
            >
              <div className="d-flex justify-content-between align-items-center">
                <Avatar
                  src={conversation.image}
                  alt={`Profile ${conversation.name}`}
                  className={classes["avatar"]}
                  size="small"
                />{" "}
                &nbsp;
                {conversation?.name} <br />
                {conversation?.nick_name
                  ? "(" + conversation?.nick_name + ")"
                  : ""}
              </div>

              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  style={{ border: "none" }}
                >
                  {/* <BiDotsHorizontalRounded size={35}/> */}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    onClick={(e) =>
                      handleShowNickNameModal(
                        conversation?.id,
                        conversation?.nick_name
                      )
                    }
                  >
                    {translate("Nick Name")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={(e) =>
                      handleShowNoteModal(conversation?.id, conversation?.note)
                    }
                  >
                    {translate("Note")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={(e) =>
                      handleDeleteConversation(conversation?.conversation_id)
                    }
                  >
                    {translate("Delete")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* <div>
                      </div> */}
            </li>
          ))}
        </ul>
      </div>
      <div className={classes["rightbar"]}>
        <div className={classes["navBar"]}>
          {selectedUser ? (
            <h2 className={classes["selectedUser"]}>
              <Avatar
                src={selectedUser.image}
                alt={`Profile ${selectedUser.name}`}
                size="small"
              />
              {selectedUser.name}
            </h2>
          ) : (
            <h2 className={classes["selectedUser"]}>{translate("Chat")}</h2>
          )}
          <i
            className={`fa fa-bars ${classes["hamburger"]}`}
            onClick={handleHamburgerClick}
          ></i>
          <div
            className={sidebar ? "backdrop" : "backdropNone"}
            onClick={backdropHandle}
          ></div>
        </div>
        <div className={classes["chatWrapper"]}>
          <div className={classes["chat-messages"]} id="chat-messages">
            {chats && chats.length > 0 && (
              <div className="text-center">
                <span
                  className={`badge badge-primary ${classes["my-bdg"]}`}
                  onClick={loadMoreMessages}
                >
                  {translate("Load More Messages")}
                </span>
              </div>
            )}
            {chats &&
              chats?.map((c) => (
                <>
                  <ChatDisplayDate c={c} />
                  {c?.type == "message" && (
                    <MessageList
                      className={classes["message-list"]}
                      lockable={true}
                      toBottomHeight={"100%"}
                      dataSource={
                        [
                          {
                            position:
                              c?.sender_id == logged_id ? "right" : "left",
                            type: "text",
                            text: c?.content,
                            date: new Date(c?.created_at),
                            dateString: `${new Date(
                              c?.created_at
                            ).getHours()}:${new Date(c?.created_at)
                              .getMinutes()
                              .toString()
                              .padStart(2, "0")}${
                              new Date(c?.created_at).getHours() >= 12
                                ? "PM"
                                : "AM"
                            }`,
                          },
                        ] || []
                      }
                    />
                  )}
                  {c?.type == "item" && (
                    <div className="card">
                      <div className="card-header">
                        <h3>
                          <div className={classes["card-head"]}>
                            <span>{ucwords(JSON.parse(c?.content)?.item)}</span>
                            {JSON.parse(c?.content)?.discount > 0.0 && (
                              <span className="badge badge-success">
                                {translate("Discount")} :{" "}
                                {showAmount(JSON.parse(c?.content)?.discount)}{" "}
                                {translate("%")}
                              </span>
                            )}
                          </div>
                        </h3>
                        {JSON.parse(c?.content)?.url && (
                          <div>
                            (
                            <Link
                              to={JSON.parse(c?.content)?.url}
                              target="_blank"
                            >
                              {JSON.parse(c?.content)?.url}
                            </Link>
                            )
                          </div>
                        )}
                      </div>
                      <div className="card-body">
                        <table className="table table-striped">
                          <tr>
                            <th>{translate("Inquiry Boat Name")}</th>
                            <td>{JSON.parse(c?.content)?.name}</td>
                          </tr>

                          <tr>
                            <th>{translate("Boat Check-in & Check-out")}</th>
                            <td>
                              {JSON.parse(c?.content)?.start} {translate("To")}{" "}
                              {JSON.parse(c?.content)?.end} (
                              {JSON.parse(c?.content)?.days} {translate("Days")}{" "}
                              )
                            </td>
                          </tr>

                          <tr>
                            <th>{translate("Adult/Child")}</th>
                            <td>
                              {JSON.parse(c?.content)?.adult}
                              {JSON.parse(c?.content)?.child}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="card-footer">
                        <button
                          type="button"
                          onClick={openOfferModal}
                          ClassName="btn btn-primary m-1"
                        >
                          {translate("Send Offer")}
                        </button>
                      </div>
                    </div>
                  )}
                  {c?.type == "offer" && (
                    <div className="card">
                      <div className="card-header bg-dark text-white">
                        <h3>
                          <div className={classes["card-head"]}>
                            <span>{ucwords("custom " + c?.type)}</span>
                          </div>
                        </h3>
                      </div>
                      <div className="card-body">
                        <div
                          style={{ border: "1px solid grey", padding: "5px" }}
                        >
                          <div>
                            <b>{translate("Offer Description")}:</b>
                          </div>
                          <div ClassName={classes["styled-line"]}></div>
                          <div style={{ marginTop: "10px" }}>
                            {JSON.parse(c?.content)?.offer}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <b>
                              {translate("Check In")} {translate("To")}{" "}
                              {translate("Check Out")}
                            </b>{" "}
                            {JSON.parse(c?.content)?.date_from}{" "}
                            {translate("To")} {JSON.parse(c?.content)?.date_to}
                          </div>
                        </div>
                        <div>
                          <div style={{ marginTop: "10px" }}>
                            <b>{translate("Offer Services")}:</b>
                          </div>
                        </div>
                        <table className="table table-striped">
                          <tbody>
                            {JSON.parse(c?.content)?.services.map(
                              (item, index) => (
                                <tr className="text-center">
                                  <th>
                                    {item["service_title"] &&
                                      translate(ucwords(item["service_title"]))}
                                  </th>
                                  <td>
                                    {item["service_price"] &&
                                      currency +
                                        translate(item["service_price"])}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                          <tfoot>
                            <tr className="text-center">
                              <th>{translate("Boat Rent")}</th>
                              <td>
                                {currency + JSON.parse(c?.content)?.base_price}
                              </td>
                            </tr>
                            <tr className="text-center">
                              <th>{translate("Total Price")}</th>
                              <td>
                                {currency + JSON.parse(c?.content)?.total_price}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      {/* <div className="card-footer d-flex justify-content-end">
                          <button type="button" disabled={isDateExpired(c?.created_at,JSON.parse(c?.content)?.offer_setting?.expire)} onClick={openOfferModal} ClassName="btn btn-secondary m-1">{translate('Accept Offer')}</button>
                        </div> */}
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
        {conversations && conversations.length > 0 ? (
          <InputMessage
            type={type}
            id={id}
            inputState={{ inputText, setInputText }}
            sidebar={sidebar}
            selectedUser={selectedUser}
            setUserSentMessage={setUserSentMessage}
            setAttachedFile={setAttachedFile}
            classes={classes}
            isOfferModalOpen={isOfferModalOpen}
            setIsOfferModalOpen={setIsOfferModalOpen}
            currency={currency}
          />
        ) : (
          <h4 className="text-center">{translate("No Chat found")}</h4>
        )}
      </div>
    </div>
  );
};

export default Chat;
