import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { Link } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";
import { AiOutlineCheck } from "react-icons/ai";
import "./CancelBookedBoat.css";
import { useLanguage } from "../../utils/LanguageContext";
import { addLeadingZero } from "../../utils/addLeadingZero";
import Loader from "../../Components/Loader";
import CreateNewBoat from "../CreateNewBoat/CreateNewBoat";
import AllBoat from "../AllBoat/AllBoat";

function CancelBookedBoat() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();
  const [activeTab, setActiveTab] = useState("cancle-booked-boat");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/booked-boat/cancel");
      setData(res.data);
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  function getStatusBadge(status) {
    if (status === 0) {
      return (
        <span className="badge badge-warning">{translate("Pending")}</span>
      );
    } else if (status === 1) {
      return (
        <span className="badge badge-success">{translate("Success")}</span>
      );
    } else if (status === 2) {
      return <span className="badge badge-danger">{translate("Cancel")}</span>;
    } else if (status === 3) {
      return (
        <span className="badge badge-success">{translate("Approved")}</span>
      );
    } else if (status === 4) {
      return (
        <span className="badge badge-success">{translate("Rejected")}</span>
      );
    }
    return null;
  }

  async function handleStatusChange(id, status) {
    try {
      setLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `owner/booked-boat/${id}/${status}`
      );
      setLoading(false);
      if (res.success) {
        Toaster("success", res?.message);
      } else {
        Toaster("error", "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
    }
  }

  // Function to render different content based on the active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case "cancle-booked-boat":
        return (
          <>
            <div className="table-responsive">
              <div className="table-wrapper">
                <table
                  id="mytable"
                  className="table table-bordred table-striped"
                >
                  <thead className="cancelBoat-table-th">
                    <th>{translate("S.N.")}</th>
                    <th>{translate("Name")}</th>
                    <th>{translate("Boat Type")}</th>
                    <th>{translate("Location")}</th>
                    <th>{translate("Price")}</th>
                    <th>{translate("Discount")}</th>
                    <th>{translate("Discounted Price")}</th>
                    <th>{translate("Status")}</th>
                    <th>{translate("Action")}</th>
                  </thead>
                  <tbody>
                    {data?.booked_boats?.data?.map((row, index) => (
                      <tr key={index} className="bookedBoat-td-wrapper">
                        <td style={{ paddingTop: "18px" }}>
                          <div className="sr-no-bg">0{index + 1}</div>
                        </td>
                        <td>{row?.boat.name}</td>
                        <td>{row?.boat.boat_type.name}</td>
                        <td>{row?.boat.location.name}</td>
                        <td>
                          {data?.curr_sym}
                          {showAmount(row?.boat?.price)}
                        </td>
                        <td>{showAmount(row?.boat?.discount)}%</td>
                        <td>
                          {data?.curr_sym}
                          {showAmount(row?.total_price)}
                        </td>
                        <td
                          style={{ paddingTop: "20px" }}
                          className="bookedBoat-status"
                        >
                          {getStatusBadge(row?.status)}
                        </td>
                        <td>
                          {row?.status != 1 && (
                            <Link
                              className="btn btn-sm btn-primary m-1"
                              onClick={() =>
                                handleStatusChange(row?.id, "success")
                              }
                            >
                              <AiOutlineCheck />
                            </Link>
                          )}
                          {row?.status != 2 && (
                            <Link
                              className="btn btn-sm btn-danger m-1"
                              onClick={() =>
                                handleStatusChange(row?.id, "cancel")
                              }
                            >
                              {translate("Cancel")}
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {data?.booked_boats?.data?.length == 0 && (
              <div className="pendingBoat-Img">
                <img src="/assets/icons/pendingProperty-img.png" alt="..." />
              </div>
            )}
          </>
        );

      case "add-boat":
        return (
          <div>
            <CreateNewBoat setActiveTab={setActiveTab} />
          </div>
        );
      case "all-boat":
        return (
          <div>
            <AllBoat setActiveTab={setActiveTab} />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="mainContainer">
          <div className="allProperty-headingWrapper mb-4 mt-3">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <div className="allProperty-heading pt-0">
                  {activeTab === "add-boat" && (
                    <div className="tab-container d-flex gap-3 mt-5 pt-4">
                      <div className="pendingBoat-heading"></div>
                      <div
                        className={`tab  text-white fw-bold    ${
                          activeTab === "cancle-booked-boat" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("cancle-booked-boat")}
                      ></div>
                    </div>
                  )}
                  {activeTab === "cancle-booked-boat" && (
                    <div className="tab-container">
                      <div className="pendingBoat-heading mt-0 pt-0 mb-0">
                        <h1>
                          {data?.type} {translate("Booked Boat")}
                        </h1>
                        <p>
                          {translate("Total " + data?.type + " Boats")}{" "}
                          <span>
                            {addLeadingZero(data?.booked_boats?.total)}
                          </span>
                        </p>
                      </div>
                      <div
                        className={`tab  text-white fw-bold  ${
                          activeTab === "add-boat" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("add-boat")}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12">
                <div className="">
                  {activeTab === "add-boat" && (
                    <div className="tab-container d-flex gap-3">
                      <div
                        className={`tab addProperty-addBtn text-white fw-bold cursor-pointer   ${
                          activeTab === "cancle-booked-boat" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("cancle-booked-boat")}
                      >
                        {translate("All Booked Boats")}
                      </div>
                    </div>
                  )}
                  {activeTab === "cancle-booked-boat" && (
                    <div className="tab-container d-flex gap-3">
                      <div
                        className={`tab addProperty-addBtn text-white fw-bold   ${
                          activeTab === "add-boat" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("add-boat")}
                      >
                        {translate("+ Add New Boats")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {renderTabContent()}
        </div>
      )}
    </>
  );
}

export default CancelBookedBoat;
