import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import "./DashboardLogout.css";
import { BsCheck } from "react-icons/bs";
import { clearUser } from "../../UserDashboardComponent/UserProfile/userSlice";
import Toaster from "../../Components/Toaster";
import { useLanguage } from "../../utils/LanguageContext";

function DashboardLogout() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { langData, fetchLangData, translate } = useLanguage();

  const handleShow = () => {
    dispatch(clearUser());
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userType");
    localStorage.removeItem("userEmail");
    Toaster("success", "Logout successfully");
    navigate("/");
  };
  const handleClose = () => {
    setShow(false);
    navigate("/");
  };

  return (
    <div className="owner-logout">
      <Link
        className="side-nav-icon"
        onClick={handleShow}
        style={{ fontSize: "20px", textDecoration: "none" }}
      >
        {/* <img alt="" src="/assets/dashboardImages/logOutIcon.png" /> */}
        <p className="logout-button-owner-login">{translate("Log Out")}</p>
      </Link>
      <Modal show={show} style={{ paddingTop: "100px" }}>
        <div className=" modal-confirm modal-bg">
          <div className="modal-header">
            <div className="icon-boxcheck">
              <BsCheck style={{ fontSize: "50px" }} />
            </div>
            <h4 className="modal-title w-100 text-center mt-3">
              {translate("Awesome!")}
            </h4>
          </div>

          <div className="modal-body">
            <p className="text-center">
              {translate("Your are successfully logout.")}
            </p>
          </div>

          <div className="modal-footer modalOk-btn">
            <button
              style={{
                background: "#70B25C",
                color: "#FFF",
                border: "none",
                fontWeight: "bold",
                borderRadius: "5px",
              }}
              className=" w-100 p-2"
              onClick={handleClose}
              data-dismiss="modal"
              aria-label="Close"
            >
              {translate("OK")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DashboardLogout;
