import React, { useEffect, useState } from "react";
import classes from "./Requests.module.css";
import Toaster from "../../Components/Toaster";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import makeHttpRequest from "../../utils/api";
import { Stack } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import Navbar from "../../Components/Navbar/Navbar";
import { MdClose } from "react-icons/md";
import { showAmount } from "../../utils/showAmount";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useLanguage } from "../../utils/LanguageContext";
import "../../main.css";

const CheckoutForm = (prop) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const { translate } = useLanguage();

  const handleSubmit = async (e) => {
    try {
      if (elements == null) {
        return;
      }
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message);
        return;
      }

      setIsLoading(true);
      const res = await makeHttpRequest("POST", prop.intent, {
        trx: prop.trx,
      });
      const { clientSecret } = res.data;

      // const stripe = await stripePromise;
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url:
            prop.redirect_url !== ""
              ? prop.redirect_url
              : window.location.protocol +
                "//" +
                window.location.hostname +
                "/booking-confirmed",
        },
      });
      setIsLoading(false);

      if (error) {
        console.log("error:", error);
        setErrorMessage(error.message);
        Toaster("error", error.message);
      } else {
        Toaster("success", "Payment");
      }
    } catch (err) {
      console.log("err:", err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PaymentElement />
      <button
        className="btn btn-outline-primary d-block py-3 font-weight-bold mt-3 w-100"
        type="submit"
        disabled={!stripe || !elements || isLoading}
        onClick={handleSubmit}
      >
        {isLoading ? "Loading..." : "Pay now"}
      </button>
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};
let style = {};
let style2 = {};
const isMobile = window.innerWidth <= 767;
if (isMobile) {
  style = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
    position: "absolute", // Add this line
    top: "50%", // Add this line
    left: "50%", // Add this line
    transform: "translate(-50%, -50%)", // Add this line
  };
  style2 = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
    position: "absolute", // Add this line
    top: "50%", // Add this line
    left: "50%", // Add this line
    transform: "translate(-50%, -50%)", // Add this line
  };
} else {
  style = {
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
    position: "absolute", // Add this line
    top: "50%", // Add this line
    left: "50%", // Add this line
    transform: "translate(-50%, -50%)", // Add this line
  };
  style2 = {
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
    position: "absolute", // Add this line
    top: "50%", // Add this line
    left: "50%", // Add this line
    transform: "translate(-50%, -50%)", // Add this line
  };
}

function Requests() {
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [owner_name, setOwnerName] = useState("");
  const [owner_phone, setOwnerPhone] = useState("");
  const [owner_email, setOwnerEmail] = useState("");
  const [location, setLocation] = useState("");
  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");
  const [phone, setPhone] = useState("");
  const [rno, setRno] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [booked_id, setBookedId] = useState();
  const [booked_type, setBookedType] = useState();
  const [booked_payment_gateway, setBookedPaymentGateway] = useState();
  const [preview_data, setPreviewData] = useState();
  const [confirm_data, setConfirmData] = useState("");
  const { translate } = useLanguage();

  const [open, setOpen] = useState(false);
  const handleOpen = async (event) => {
    const dataName = event.target.getAttribute("data-name");
    const dataOwnerName = event.target.getAttribute("data-owner_name");
    const dataOwnerPhone = event.target.getAttribute("data-owner_phone");
    const dataOwnerEmail = event.target.getAttribute("data-owner_email");
    const dataLocation = event.target.getAttribute("data-location");
    const dataCheckin = event.target.getAttribute("data-from");
    const dataCheckout = event.target.getAttribute("data-to");
    const dataPhone = event.target.getAttribute("data-phone");
    const dataRno = event.target.getAttribute("data-rno");
    const dataPrice = event.target.getAttribute("data-price");
    const dataImage = event.target.getAttribute("data-image");

    setName(dataName);
    setOwnerName(dataOwnerName);
    setOwnerPhone(dataOwnerPhone);
    setOwnerEmail(dataOwnerEmail);
    setLocation(dataLocation);
    setCheckin(dataCheckin);
    setCheckout(dataCheckout);
    setPhone(dataPhone);
    setRno(dataRno);
    setPrice(dataPrice);
    setImage(dataImage);

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const [payment_open, setPaymentOpen] = useState(false);
  const handlePaymentClose = () => setPaymentOpen(false);

  const [preview_open, setPreviewOpen] = useState(false);
  const handlePreviewClose = () => setPreviewOpen(false);

  const [confirm_open, setConfirmOpen] = useState(false);

  const stripePromise = loadStripe(confirm_data?.data?.val?.key);

  const options = {
    mode: "payment",
    name: confirm_data?.data?.val?.name,
    amount: confirm_data?.data?.val?.amount,
    currency: confirm_data?.data?.val?.currency.toLowerCase(),
    description: confirm_data?.data?.val?.description,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  useEffect(() => {
    getData();
  }, []);

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "user/availability");
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // on setup payment
  const handleSetupPayment = async (id, type) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "user/payment", {
        id: id,
        type: type,
      });
      setLoading(false);
      if (res.success) {
        setBookedPaymentGateway(res.data);
        setPaymentOpen(true);
        setBookedId(id);
        setBookedType(type);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePayNow = async (currency, method_code) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "user/payment/insert", {
        id: booked_id,
        type: booked_type,
        currency: currency,
        method_code: method_code,
      });
      setLoading(false);
      if (res.success) {
        setPreviewData(res.data);
        setPreviewOpen(true);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleConfirmPayment = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "user/payment/confirm", {
        track: preview_data?.track,
      });

      setLoading(false);
      if (res.success) {
        setConfirmData(res.data);
        setConfirmOpen(true);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container my-4">
        <div className="row">
          <div className="col-lg-3 col-md-3 d-none d-md-block">
            <UserSideNavbar />
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes["requestmodal-wrapper"]} sx={style}>
                  <div className={classes["requestmodal-heading"]}>
                    <h1>{translate("Booking Detail")}</h1>
                    <MdClose
                      onClick={handleClose}
                      style={{
                        color: "#FFF",
                        cursor: "pointer",
                        fontSize: "38px",
                        paddingRight: "10px",
                      }}
                    />
                  </div>
                  <div className={classes["requestsubmit-inputs"]}>
                    <div
                      className={`${classes.requestmodal} ${classes["scrollable-content"]}`}
                    >
                      <table className="table table-hover table-bordered">
                        <tbody>
                          <tr>
                            <th>{translate("Name")}</th>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <th>{translate("Location")}</th>
                            <td>{location}</td>
                          </tr>
                          <tr>
                            <th>{translate("Check in")}</th>
                            <td>{checkin}</td>
                          </tr>
                          <tr>
                            <th>{translate("Check out")}</th>
                            <td>{checkout}</td>
                          </tr>
                          {owner_name && (
                            <tr>
                              <th>{translate("Owner")}</th>
                              <td>{owner_name}</td>
                            </tr>
                          )}
                          {owner_phone && (
                            <tr>
                              <th>{translate("Owner Phone")}</th>
                              <td>{owner_phone}</td>
                            </tr>
                          )}
                          {owner_email && (
                            <tr>
                              <th>{translate("Owner Email")}</th>
                              <td>{owner_email}</td>
                            </tr>
                          )}
                          {phone && (
                            <tr>
                              <th>{translate("Phone")}</th>
                              <td>{phone}</td>
                            </tr>
                          )}
                          {rno && (
                            <tr>
                              <th>{translate("Room")}</th>
                              <td>{rno}</td>
                            </tr>
                          )}
                          <tr>
                            <th>{translate("Price")}</th>
                            <td>{price}</td>
                          </tr>
                          {image && (
                            <tr>
                              <th colSpan={2}>{translate("Image")}</th>
                            </tr>
                          )}
                          {image && (
                            <tr>
                              <td colSpan={2}>
                                <img
                                  src={image.split(",")[0]}
                                  alt={name}
                                  style={{ height: "150px" }}
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
            {/* payment modal */}
            <Modal
              open={payment_open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className={classes["requestmodal-wrapper"]}>
                <Box sx={style2}>
                  <div className={classes["requestmodal-heading"]}>
                    <h1>{translate("Payment Gateways")}</h1>
                    <MdClose
                      onClick={handlePaymentClose}
                      style={{
                        color: "#FFF",
                        cursor: "pointer",
                        fontSize: "38px",
                        paddingRight: "10px",
                      }}
                    />
                  </div>
                  <div className={classes["requestsubmit-inputs"]}>
                    <div className={classes["requestmodalcrollable-content"]}>
                      <div className="row">
                        {booked_payment_gateway?.gatewayCurrency?.map(
                          (gateway, index) => (
                            <div key={index} className="col-6">
                              <div className="row">
                                <div className="col-12 m-2">
                                  <h4 className="text-center">
                                    {gateway.name}
                                  </h4>
                                </div>
                                <div className="col-12 d-flex justify-content-center m-2">
                                  <img
                                    src={gateway.image}
                                    alt={gateway.name}
                                    width={200}
                                    height={200}
                                  />
                                </div>
                                <div className="col-12 d-flex justify-content-center  m-2">
                                  <button
                                    className="btn btn-outline-info"
                                    onClick={() =>
                                      handlePayNow(
                                        gateway.currency,
                                        gateway.method_code
                                      )
                                    }
                                  >
                                    {translate("Pay Now")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            </Modal>
            {/* Preview Modal */}
            <Modal
              open={preview_open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className={classes["requestmodal-wrapper"]}>
                <Box sx={style2}>
                  <div className={classes["requestmodal-heading"]}>
                    <h1>{translate("Preview Payment")}</h1>
                    <MdClose
                      onClick={handlePreviewClose}
                      style={{
                        color: "#FFF",
                        cursor: "pointer",
                        fontSize: "38px",
                        paddingRight: "10px",
                      }}
                    />
                  </div>
                  <div className={classes["requestsubmit-inputs"]}>
                    <div className={classes["requestmodalcrollable-content"]}>
                      <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="custom--card card-deposit text-center">
                            <div className="card-body card-body-deposit">
                              <div className="row">
                                <div className="col-6">
                                  <ul className="list-group text-center">
                                    <p
                                      key={0}
                                      className="list-group-item img_list pb-4"
                                    >
                                      <img
                                        src={preview_data?.data?.image}
                                        alt="Payment Logo"
                                        width={200}
                                        height={200}
                                        className="w-50"
                                      />
                                    </p>
                                  </ul>
                                </div>
                                <div className="col-6">
                                  <ul className="list-group text-center">
                                    <p
                                      key={0}
                                      className="list-group-item d-flex justify-content-between"
                                    >
                                      {translate("Amount")}
                                      <strong>
                                        {showAmount(preview_data?.data?.amount)}{" "}
                                        {preview_data?.curr_name}
                                      </strong>
                                    </p>
                                    <p
                                      key={1}
                                      className="list-group-item d-flex justify-content-between"
                                    >
                                      {translate("Charge")}
                                      <strong>
                                        {showAmount(preview_data?.data?.charge)}{" "}
                                        {preview_data?.curr_name}
                                      </strong>
                                    </p>
                                    <p
                                      key={2}
                                      className="list-group-item d-flex justify-content-between"
                                    >
                                      {translate("Payable")}{" "}
                                      <strong>
                                        {" "}
                                        {showAmount(
                                          preview_data?.data?.amount +
                                            preview_data?.data?.charge
                                        )}
                                        {preview_data?.curr_name}
                                      </strong>
                                    </p>
                                    <p
                                      key={3}
                                      className="list-group-item d-flex justify-content-between"
                                    >
                                      {translate("Conversion Rate")}{" "}
                                      <strong>
                                        1 {preview_data?.curr_name} ={" "}
                                        {showAmount(preview_data?.data?.rate)}{" "}
                                        {preview_data?.data?.base_currency}
                                      </strong>
                                    </p>
                                    <p
                                      key={4}
                                      className="list-group-item d-flex justify-content-between"
                                    >
                                      {translate("In")}{" "}
                                      {preview_data?.data?.base_currency}:
                                      <strong>
                                        {showAmount(
                                          preview_data?.data?.final_amo
                                        )}
                                      </strong>
                                    </p>
                                    {preview_data?.data?.crypto == 1 && (
                                      <p key={5} className="list-group-item">
                                        {translate("Conversion with")}
                                        <b>
                                          {" "}
                                          {preview_data?.data?.method_currency}
                                        </b>{" "}
                                        {translate(
                                          "and final value will Show on next step"
                                        )}
                                      </p>
                                    )}
                                  </ul>
                                </div>
                              </div>

                              {1000 > preview_data?.data?.method_code ? (
                                <button
                                  // {{route('user.deposit.confirm')}}"
                                  className="btn btn-outline-primary d-block py-3 font-weight-bold mt-3 w-100"
                                  onClick={handleConfirmPayment}
                                >
                                  {translate("Proceed")}
                                </button>
                              ) : (
                                <a
                                  href="{{route('user.deposit.manual.confirm')}}"
                                  className="btn btn-outline-primary d-block py-3 font-weight-bold mt-3"
                                  onClick={handleConfirmPayment}
                                >
                                  {translate("Proceed")}
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            </Modal>
            {/* Confirm Modal */}
            <Modal
              open={confirm_open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className={classes["requestmodal-wrapper"]}>
                <Box sx={style2}>
                  <div className={classes["requestmodal-heading"]}>
                    <h1>{translate("Preview Payment")}</h1>
                    <MdClose
                      onClick={handleConfirmClose}
                      style={{
                        color: "#FFF",
                        cursor: "pointer",
                        fontSize: "38px",
                        paddingRight: "10px",
                      }}
                    />
                  </div>
                  <div className={classes["requestsubmit-inputs"]}>
                    <div className={classes["requestmodalcrollable-content"]}>
                      <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="custom--card card-deposit text-center">
                            <div className="card-body card-body-deposit">
                              <div className="row">
                                <div className="col-12">
                                  <div className="custom--card">
                                    <div className="card-header bg--base">
                                      <h5 className="text-white text-center">
                                        {translate("Payment Confirm")}
                                      </h5>
                                    </div>
                                    <div className="card-body text-center">
                                      <h3 className="text-center mb-2">
                                        {translate("Please Pay")}
                                        {showAmount(
                                          confirm_data?.desposit?.final_amo
                                        )}
                                        {
                                          confirm_data?.desposit
                                            ?.method_currency
                                        }
                                      </h3>
                                      <h3>
                                        {translate("To book the property")}
                                      </h3>
                                      <Elements
                                        stripe={stripePromise}
                                        options={options}
                                      >
                                        <CheckoutForm
                                          intent={confirm_data?.data?.intent}
                                          trx={confirm_data?.data?.val?.trx}
                                          redirect_url={
                                            confirm_data?.data?.redirect_url
                                          }
                                        />
                                      </Elements>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            </Modal>
            {/* <div className="propertyHistory-heading">
              <h1>{translate('Availability Check')}</h1>
            </div> */}

            {loading && (
              <Stack
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
              </Stack>
            )}
            {!loading && (
              <>
                {/* Requested property */}
                {data?.requested_properties?.length > 0 && (
                  <div className="content-bg text-center">
                    <div className={classes["request-viewallWrapper"]}>
                      <h2>{translate("Property Inquiry Request")}</h2>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className={classes["table-wrapper"]}>
                        <table
                          id="mytable"
                          className="table table-bordred table-striped"
                        >
                          <thead className={classes["request-table-th"]}>
                            <th>{translate("Property Name")}</th>
                            <th>{translate("Location")}</th>
                            <th>{translate("Phone")}</th>
                            <th>{translate("Action")}</th>
                          </thead>
                          <tbody>
                            {data?.requested_properties?.map((p, i) => (
                              <tr key={i}>
                                <td>{p?.property?.name}</td>
                                <td>{p?.property?.location}</td>
                                <td>
                                  {p?.property?.phone
                                    ? p?.property?.phone
                                    : "Not Available"}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-small"
                                    style={{
                                      color: "#8bc53e",
                                    }}
                                    data-name={p?.property?.name}
                                    data-owner_name={p?.property?.owner_name}
                                    data-owner_phone={p?.property?.owner_phone}
                                    data-owner_email={p?.property?.owner_email}
                                    data-location={p?.property?.location}
                                    data-from={p?.date_from}
                                    data-to={p?.date_to}
                                    data-phone={p?.property?.phone}
                                    data-rno={p?.bookedRooms}
                                    data-price={data?.curr_sym + p?.total_price}
                                    data-image={p?.property?.thumbnail}
                                    onClick={handleOpen}
                                  >
                                    {translate("view")}
                                  </button>
                                  {/* <AiFillEye
                                  style={{
                                    color: "#8bc53e",
                                    cursor: "pointer",
                                    fontSize: "25px",
                                  }}
                                /> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {/* Requested boat */}
                {data?.requested_boats?.length > 0 && (
                  <div className="content-bg text-center mt-0">
                    <div className={classes["request-viewallWrapper"]}>
                      <h2>{translate("Boat Inquiry Request")}</h2>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className={classes["table-wrapper"]}>
                        <table
                          id="mytable"
                          className="table table-bordred table-striped"
                        >
                          <thead className={classes["request-table-th"]}>
                            <th>{translate("Boat Name")}</th>
                            <th>{translate("Location")}</th>
                            <th>{translate("Phone")}</th>
                            <th>{translate("Rate")}</th>
                          </thead>
                          <tbody>
                            {data?.requested_boats?.map((p, i) => (
                              <tr key={i}>
                                <td>{p?.boat?.name}</td>
                                <td>{p?.boat?.location}</td>
                                <td>
                                  {p?.boat?.phone
                                    ? p?.boat?.phone
                                    : "Not Available"}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-small"
                                    style={{
                                      color: "#8bc53e",
                                    }}
                                    data-name={p?.boat?.name}
                                    data-owner_name={p?.boat?.owner_name}
                                    data-owner_phone={p?.property?.owner_phone}
                                    data-owner_email={p?.property?.owner_email}
                                    data-location={p?.boat?.location}
                                    data-from={p?.date_from}
                                    data-to={p?.date_to}
                                    data-phone={p?.boat?.phone}
                                    data-price={data?.curr_sym + p?.total_price}
                                    data-image={p?.boat?.thumbnail}
                                    onClick={handleOpen}
                                  >
                                    {translate("view")}
                                  </button>
                                  {/* <AiFillEye
                                  style={{
                                    color: "#8bc53e",
                                    cursor: "pointer",
                                    fontSize: "25px",
                                  }}
                                /> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {/* Approved property */}
                {data?.approved_properties?.length > 0 && (
                  <div className="content-bg text-center mt-4">
                    <div className={classes["request-viewallWrapper"]}>
                      <h2>{translate("Property Approved Request")}</h2>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className={classes["table-wrapper"]}>
                        <table
                          id="mytable"
                          className="table table-bordred table-striped"
                        >
                          <thead className={classes["request-table-th"]}>
                            <th>{translate("Property Name")}</th>
                            <th>{translate("Location")}</th>
                            <th>{translate("Phone")}</th>
                            <th>{translate("Rate")}</th>
                          </thead>
                          <tbody>
                            {data?.approved_properties?.map((p, i) => (
                              <tr key={i}>
                                <td>{p?.property?.name}</td>
                                <td>{p?.property?.location}</td>
                                <td>
                                  {p?.property?.phone
                                    ? p?.property?.phone
                                    : "Not Available"}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-small"
                                    style={{
                                      color: "#8bc53e",
                                    }}
                                    data-name={p?.property?.name}
                                    data-owner_name={p?.property?.owner_name}
                                    data-owner_phone={p?.property?.owner_phone}
                                    data-owner_email={p?.property?.owner_email}
                                    data-location={p?.property?.location}
                                    data-from={p?.date_from}
                                    data-to={p?.date_to}
                                    data-phone={p?.property?.phone}
                                    data-rno={p?.bookedRooms}
                                    data-price={data?.curr_sym + p?.total_price}
                                    data-image={p?.property?.thumbnail}
                                    onClick={handleOpen}
                                  >
                                    {translate("view")}
                                  </button>
                                  {/* <AiFillEye
                                  style={{
                                    color: "#8bc53e",
                                    cursor: "pointer",
                                    fontSize: "25px",
                                  }}
                                /> */}{" "}
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      handleSetupPayment(p?.id, "property")
                                    }
                                  >
                                    {translate("Pay Now")}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {/* Approved boat */}
                {data?.approved_boats?.length > 0 && (
                  <div className="content-bg text-center mt-4">
                    <div className={classes["request-viewallWrapper"]}>
                      <h2>{translate("Boat Approved Request")}</h2>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className={classes["table-wrapper"]}>
                        <table
                          id="mytable"
                          className="table table-bordred table-striped"
                        >
                          <thead className={classes["request-table-th"]}>
                            <th>{translate("Boat Name")}</th>
                            <th>{translate("Location")}</th>
                            <th>{translate("Phone")}</th>
                            <th>{translate("Rate")}</th>
                          </thead>
                          <tbody>
                            {data?.approved_boats?.map((p, i) => (
                              <tr key={i}>
                                <td>{p?.boat?.name}</td>
                                <td>{p?.boat?.location}</td>
                                <td>
                                  {p?.boat?.phone
                                    ? p?.boat?.phone
                                    : "Not Available"}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-small"
                                    style={{
                                      color: "#8bc53e",
                                    }}
                                    data-name={p?.boat?.name}
                                    data-owner_name={p?.boat?.owner_name}
                                    data-owner_phone={p?.property?.owner_phone}
                                    data-owner_email={p?.property?.owner_email}
                                    data-location={p?.boat?.location}
                                    data-from={p?.date_from}
                                    data-to={p?.date_to}
                                    data-phone={p?.boat?.phone}
                                    data-rno={p?.bookedRooms}
                                    data-price={data?.curr_sym + p?.total_price}
                                    data-image={p?.boat?.thumbnail}
                                    onClick={handleOpen}
                                  >
                                    {translate("view")}
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      handleSetupPayment(p?.id, "boat")
                                    }
                                  >
                                    {translate("Pay Now")}
                                  </button>
                                  {/* <AiFillEye
                                  style={{
                                    color: "#8bc53e",
                                    cursor: "pointer",
                                    fontSize: "25px",
                                  }}
                                /> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {/* Rejected property */}
                {data?.rejected_properties?.lenght > 0 && (
                  <div className="content-bg text-center mt-4">
                    <div className={classes["request-viewallWrapper"]}>
                      <h2>{translate("Property Rejected Request")}</h2>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className={classes["table-wrapper"]}>
                        <table
                          id="mytable"
                          className="table table-bordred table-striped"
                        >
                          <thead className={classes["request-table-th"]}>
                            <th>{translate("Property Name")}</th>
                            <th>{translate("Location")}</th>
                            <th>{translate("Phone")}</th>
                            <th>{translate("Rate")}</th>
                          </thead>
                          <tbody>
                            {data?.rejected_properties?.map((p, i) => (
                              <tr key={i}>
                                <td>{p?.property?.name}</td>
                                <td>{p?.property?.location}</td>
                                <td>
                                  {p?.property?.phone
                                    ? p?.property?.phone
                                    : "Not Available"}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-small"
                                    style={{
                                      color: "#8bc53e",
                                    }}
                                    data-name={p?.property?.name}
                                    data-owner_name={p?.property?.owner_name}
                                    data-owner_phone={p?.property?.owner_phone}
                                    data-owner_email={p?.property?.owner_email}
                                    data-location={p?.property?.location}
                                    data-from={p?.date_from}
                                    data-to={p?.date_to}
                                    data-phone={p?.property?.phone}
                                    data-rno={p?.bookedRooms}
                                    data-price={data?.curr_sym + p?.total_price}
                                    data-image={p?.property?.thumbnail}
                                    onClick={handleOpen}
                                  >
                                    {translate("view")}
                                  </button>
                                  {/* <AiFillEye
                                  style={{
                                    color: "#8bc53e",
                                    cursor: "pointer",
                                    fontSize: "25px",
                                  }}
                                /> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {/* Reject boat */}
                {data?.rejected_boats?.length > 0 && (
                  <div className="content-bg text-center mt-4">
                    <div className={classes["request-viewallWrapper"]}>
                      <h2>{translate("Boat Rejected Request")}</h2>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className={classes["table-wrapper"]}>
                        <table
                          id="mytable"
                          className="table table-bordred table-striped"
                        >
                          <thead className={classes["request-table-th"]}>
                            <th>{translate("Boat Name")}</th>
                            <th>{translate("Location")}</th>
                            <th>{translate("Phone")}</th>
                            <th>{translate("Rate")}</th>
                          </thead>
                          <tbody>
                            {data?.rejected_boats?.map((p, i) => (
                              <tr key={i}>
                                <td>{p?.boat?.name}</td>
                                <td>{p?.boat?.location}</td>
                                <td>
                                  {p?.boat?.phone
                                    ? p?.boat?.phone
                                    : "Not Available"}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-small"
                                    style={{
                                      color: "#8bc53e",
                                    }}
                                    data-name={p?.boat?.name}
                                    data-owner_name={p?.boat?.owner_name}
                                    data-owner_phone={p?.property?.owner_phone}
                                    data-owner_email={p?.property?.owner_email}
                                    data-location={p?.boat?.location}
                                    data-from={p?.date_from}
                                    data-to={p?.date_to}
                                    data-phone={p?.boat?.phone}
                                    data-price={data?.curr_sym + p?.total_price}
                                    data-image={p?.boat?.thumbnail}
                                    onClick={handleOpen}
                                  >
                                    {translate("view")}
                                  </button>
                                  {/* <AiFillEye
                                  style={{
                                    color: "#8bc53e",
                                    cursor: "pointer",
                                    fontSize: "25px",
                                  }}
                                /> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {data?.requested_properties?.length < 1 &&
                  data?.requested_boats?.length < 1 &&
                  data?.approved_properties?.length < 1 &&
                  data?.approved_boats?.length < 1 && (
                    <div className="content-bg text-center">
                      <div className={classes["request-viewallWrapper"]}>
                        <h2 className="text-center">
                          {translate("No Data Found")}
                        </h2>
                      </div>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Requests;
