import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Loader from "../../Components/Loader";
import "./RoomCategory.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";
import { useLanguage } from "../../utils/LanguageContext";
import { CircularProgress, Stack } from "@mui/material";

function EditRoomCategory() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { langData, fetchLangData, translate } = useLanguage();

  const [rooms, setRooms] = useState([
    {
      id: "",
      room_number: "",
      adult: "",
      child: "",
      price: "",
    },
  ]);
  let initialState = {
    room_category: "",
    room_category_id: "",
    property_id: "",
    amenities: [],
    id: [],
    room_number: [],
    adult: [],
    child: [],
    price: [],
  };
  const [data, setData] = useState("");
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `owner/room-category/${id}/edit`
      );

      initialState = {
        room_category: res?.data?.category?.name,
        room_category_id: res?.data?.category?.id,
        property_id: res?.data?.category?.property_id,
        amenities: res?.data?.already_amenities,
        id: [],
        room_number: [],
        adult: [],
        child: [],
        price: [],
      };

      const arr =
        res?.data?.category?.rooms?.map(function (r) {
          return {
            id: r?.id,
            room_number: r?.room_number,
            adult: r?.adult,
            child: r?.child,
            price: showAmount(r?.price),
          };
        }) || [];
      setRooms(arr);
      setState(initialState);
      setData(res?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // append code
  // Function to add a new room
  const addRoom = () => {
    setRooms([
      ...rooms,
      { id: "", room_number: "", adult: "", child: "", price: "" },
    ]);
  };

  // Function to update the room details when input fields change
  const handleRoomChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRooms = [...rooms];
    updatedRooms[index][name] = value;
    setRooms(updatedRooms);
    setState((prev) => ({
      ...prev,
      id: updatedRooms.map((room) => room.id),
      room_number: updatedRooms.map((room) => room.room_number),
      adult: updatedRooms.map((room) => room.adult),
      child: updatedRooms.map((room) => room.child),
      price: updatedRooms.map((room) => room.price),
      // rooms: updatedRooms,
    }));
  };

  // Function to remove a room
  const removeRoom = (index) => {
    const updatedRooms = [...rooms];
    updatedRooms.splice(index, 1);
    setRooms(updatedRooms);
    setState((prev) => ({
      ...prev,
      id: updatedRooms.map((room) => room.id),
      room_number: updatedRooms.map((room) => room.room_number),
      adult: updatedRooms.map((room) => room.adult),
      child: updatedRooms.map((room) => room.child),
      price: updatedRooms.map((room) => room.price),
      // rooms: updatedRooms,
    }));
  };

  function handleAmenitiesChange(amenityId) {
    setState((prevState) => {
      if (prevState.amenities.includes(amenityId)) {
        // If the amenity is already selected, remove it from the array
        return {
          ...prevState,
          amenities: prevState.amenities.filter((id) => id !== amenityId),
        };
      } else {
        // If the amenity is not selected, add it to the array
        return {
          ...prevState,
          amenities: [...prevState.amenities, amenityId],
        };
      }
    });
  }

  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest(
        "POST",
        "owner/room-category/update/" + id,
        state
      );
      setLoading(false);

      if (res.success) {
        navigate("/dashboard", { state: "roomCategory" });
      } else {
        // Handle error (e.g., display an error message)
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="container">
          <div className="roomCategory-headingWrapper">
            <div className="roomCategory-heading">
              <h1>{translate("Room Categories")}</h1>
            </div>
          </div>
          <div className="inner-continer">
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12 mt-2">
                    <label>{translate("Room Category")}</label>
                    <input
                      type="text"
                      placeholder={translate("Category Name")}
                      className="form-control"
                      value={state.room_category}
                      onChange={(e) =>
                        setState({ ...state, room_category: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label>{translate("Select Property")}</label>
                    <select
                      className="form-control"
                      value={state.property_id}
                      onChange={(e) =>
                        setState({ ...state, property_id: e.target.value })
                      }
                    >
                      <option value="">
                        {translate("Select one Property")}
                      </option>
                      {data?.properties?.map((pro, index) => (
                        <option key={pro.id} value={pro.id}>
                          {pro.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card bg-white m-3">
                  <div className="card-header bg-white">
                    {translate("Amenities")}
                  </div>
                  <div className="card-body bg-white">
                    {data?.amenities?.map((am, i) => (
                      <div key={am.id}>
                        <input
                          type="checkbox"
                          onChange={(e) => handleAmenitiesChange(am.id)}
                          value={am.id}
                          checked={state.amenities.includes(am.id)}
                        />{" "}
                        {translate(am.name)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-9">
                <h2>{translate("Room List")}</h2>
              </div>
              <div className="col-md-3">
                <button className="btn btn-secondary" onClick={addRoom}>
                  {translate("+")} {translate("Add More Room")}
                </button>
              </div>
            </div>

            {rooms.map((room, index) => (
              <div className="row mt-2" key={index}>
                <div className="col-md-3">
                  <input
                    type="text"
                    placeholder={translate("Room Number")}
                    className="form-control"
                    name="room_number"
                    value={room.room_number}
                    onChange={(e) => handleRoomChange(index, e)}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    type="number"
                    placeholder={translate("Adult")}
                    className="form-control"
                    name="adult"
                    value={room.adult}
                    onChange={(e) => handleRoomChange(index, e)}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    type="number"
                    placeholder={translate("Child")}
                    className="form-control"
                    name="child"
                    value={room.child}
                    onChange={(e) => handleRoomChange(index, e)}
                  />
                </div>
                <div className="col-md-3">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={translate("Price")}
                      aria-label="Price"
                      aria-describedby="basic-addon2"
                      name="price"
                      value={room.price}
                      onChange={(e) => handleRoomChange(index, e)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon-2">
                        {data?.curr_name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  {index !== 0 && (
                    <button
                      className="btn btn-danger"
                      onClick={() => removeRoom(index)}
                    >
                      {translate(" Remove")}
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="updateBoat-submitBtn">
                  {loading && (
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
                    </Stack>
                  )}
                  {!loading && (
                    <button
                      className="text-white fw-bold"
                      onClick={handleSubmit}
                    >
                      {translate("Update Room Category")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditRoomCategory;
