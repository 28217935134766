import React, { useEffect, useState } from "react";
import classes from "./BoatBookingHistory.module.css";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { CircularProgress, Stack } from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import { AiFillEye } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { MdClose } from "react-icons/md";
import { useLanguage } from "../../utils/LanguageContext";

let style = {};
const isMobile = window.innerWidth <= 767;
if (isMobile) {
  style = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
    position: "absolute", // Add this line
    top: "50%", // Add this line
    left: "50%", // Add this line
    transform: "translate(-50%, -50%)", // Add this line
  };
} else {
  style = {
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
    position: "absolute", // Add this line
    top: "50%", // Add this line
    left: "50%", // Add this line
    transform: "translate(-50%, -50%)", // Add this line
  };
}
function BoatBookingHistory() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");
  const [phone, setPhone] = useState("");
  const [rno, setRno] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { langData, fetchLangData, translate } = useLanguage();

  const handleOpen = async (event) => {
    const dataName = event.target.getAttribute("data-name");
    const dataLocation = event.target.getAttribute("data-location");
    const dataCheckin = event.target.getAttribute("data-from");
    const dataCheckout = event.target.getAttribute("data-to");
    const dataPhone = event.target.getAttribute("data-phone");
    const dataRno = event.target.getAttribute("data-rno");
    const dataPrice = event.target.getAttribute("data-price");
    const dataImage = event.target.getAttribute("data-image");

    setName(dataName);
    setLocation(dataLocation);
    setCheckin(dataCheckin);
    setCheckout(dataCheckout);
    setPhone(dataPhone);
    setRno(dataRno);
    setPrice(dataPrice);
    setImage(dataImage);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "user/boats-history");
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container my-4">
        <div className="row">
          <div className="col-lg-3 col-md-12 col-sm-12 d-none d-lg-block">
            <UserSideNavbar />
          </div>
          <div className="col-lg-9 col-md-12 col-12">
            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className={classes["boatmodal-wrapper"]}>
                  <div className={classes["reviewmodal-heading"]}>
                    <h1>{translate("Booking Detail")}</h1>
                    <MdClose
                      onClick={handleClose}
                      style={{
                        color: "#FFF",
                        cursor: "pointer",
                        fontSize: "38px",
                        paddingRight: "10px",
                      }}
                    />
                  </div>
                  <div className={classes["propertymodal-inputs"]}>
                    <div className={classes["scrollable-content"]}>
                      <table className="table table-hover table-bordered">
                        <tbody>
                          <tr>
                            <th>{translate("Name")}</th>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <th>{translate("Location")}</th>
                            <td>{location}</td>
                          </tr>
                          <tr>
                            <th>{translate("Check in")}</th>
                            <td>{checkin}</td>
                          </tr>
                          <tr>
                            <th>{translate("Check out")}</th>
                            <td>{checkout}</td>
                          </tr>
                          {phone && (
                            <tr>
                              <th>{translate("Phone")}</th>
                              <td>{phone}</td>
                            </tr>
                          )}
                          {phone && (
                            <tr>
                              <th>{translate("Room")}</th>
                              <td>{rno}</td>
                            </tr>
                          )}
                          <tr>
                            <th>{translate("Price")}</th>
                            <td>{price}</td>
                          </tr>
                          {image && (
                            <tr>
                              <th colSpan={2}>{translate("Image")}</th>
                            </tr>
                          )}
                          {image && (
                            <tr>
                              <td colSpan={2}>
                                <img
                                  src={image.split(",")[0]}
                                  alt={name}
                                  style={{ height: "250px" }}
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
            {/* <div className="boatHistory-heading">
              <h1>{translate('Boat Booking History')}</h1>
            </div> */}
            {loading && (
              <Stack alignItems="center" justifyContent="center">
                <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
              </Stack>
            )}

            {!loading && (
              <div className={classes["boatHistory-bg"]}>
                <div className={classes["boatHistory-title"]}>
                  {data?.boatBookings?.data?.length == 0 ? (
                    <h2 className="mb-3">{data?.empty_message}</h2>
                  ) : (
                    <h2 className="mb-3">{translate(data?.page_title)}</h2>
                  )}
                </div>

                <div
                  className="table-responsive"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="table-wrapper">
                    <table
                      id="mytable"
                      className="table table-bordred table-striped"
                    >
                      <thead className={classes["boatHistory-table-th"]}>
                        <th>{translate("Boat")}</th>
                        <th>{translate("Check-in")}</th>
                        <th>{translate("Check-out")}</th>
                        <th>{translate("Phone")}</th>
                        <th>{translate("Action")}</th>
                      </thead>
                      <tbody>
                        {data?.boatBookings?.data?.map((row, index) => (
                          <tr
                            key={index}
                            className={classes["boatHistory-td-wrapper"]}
                          >
                            <td>{row?.boat?.name}</td>
                            <td>{row?.date_from}</td>
                            <td>{row?.date_to}</td>
                            <td>
                              {row?.boat?.phone
                                ? row?.boat?.phone
                                : "Not Available"}
                            </td>
                            <td>
                              {/* <AiFillEye
                                onClick={handleOpen}
                                style={{
                                  color: "#f2c821",
                                  cursor: "pointer",
                                  fontSize: "25px",
                                }} */}
                              <button
                                className="btn btn-small"
                                style={{
                                  color: "#8bc53e",
                                }}
                                data-name={row?.boat?.name}
                                data-location={row?.boat?.location}
                                data-from={row?.date_from}
                                data-to={row?.date_to}
                                data-phone={row?.boat?.phone}
                                data-rno={row?.bookedRooms}
                                data-price={data?.curr_sym + row?.total_price}
                                data-image={row?.boat?.thumbnail}
                                onClick={handleOpen}
                              >
                                {translate("view")}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BoatBookingHistory;
