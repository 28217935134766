import React from "react";
import { useLanguage } from "../../utils/LanguageContext";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { addLeadingZero } from "../../utils/addLeadingZero";
import { BsArrowRightShort } from "react-icons/bs";

function BoatType() {
  const { translate } = useLanguage();
  const { data } = useSelector((state) => state.landing);

  return (
      <div className="boat-wrapper">
    <div className="container p-3">
        <div className="best-boat-content-wrapper">
          <div className="best-boats-content">
            <div className="boatType-Images">
              <h1> {translate(data?.boat?.heading)}</h1>
              <img
                className="boatTypeImage-one"
                src="/assets/icons/aa2.jpeg"
                alt="..."
              />
              <img
                className="boatTypeImage-two"
                src="/assets/icons/aa1.png"
                alt="..."
              />
            </div>

            <p>{translate(data?.boat?.sub_heading)}</p>
          </div>
          <div className="best-boat-button">
            <Link to={"/boat"} className="link-wrapper">
              <Button variant="contained">
                {translate("Discover More")}
                <BsArrowRightShort
                  style={{ fontSize: "15px", marginLeft: "10px" }}
                />
              </Button>
            </Link>
          </div>
        </div>

        <div>
          {data?.boatTypes && data?.boatTypes?.length > 0 && (
            <div className="row boat-wrapper class1">
              {data?.boatTypes[0] && (
                <div className="col-lg-3">
                  <Card
                    sx={{
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomRightRadius: "16px",
                      borderBottomLeftRadius: "16px",
                      boxShadow: "none",
                    }}
                    className="boot-card-content-wrapper"
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/boat/search-location/${data?.boatTypes[0]?.id}/${data?.boatTypes[0]?.slug}`}
                      className="link-wrapper"
                    >
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        // height="100%"
                        image={
                          // data?.boatTypes[0].image !== ""
                          // ?
                          data?.boatTypes[0].image
                          // : "/assets/icons/fastBoat1.png"
                        }
                        style={{ backgroundColor: "#8BC53E" }}
                      />

                      <CardContent className="card-boot-content-wrapper">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="boat-card-footer"
                        >
                          {translate(data?.boatTypes[0].name)} (
                          {addLeadingZero(data?.boatTypes[0].total)})
                        </Typography>
                        <BsArrowRightShort className="boat-sec-more-icon" />
                      </CardContent>
                    </Link>
                  </Card>
                </div>
              )}

              {data?.boatTypes[1] && (
                <div className="col-lg-3">
                  <Card
                    sx={{
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomRightRadius: "16px",
                      borderBottomLeftRadius: "16px",
                      boxShadow: "none",
                    }}
                    className="boot-card-content-wrapper"
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/boat/search-location/${data?.boatTypes[1]?.id}/${data?.boatTypes[1]?.slug}`}
                      className="link-wrapper"
                    >
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        // height="100%"
                        image={
                          // data?.boatTypes[1].image !== ""
                          // ?
                          data?.boatTypes[1].image
                          // : "/assets/icons/fastBoat1.png"
                        }
                        style={{ backgroundColor: "#8BC53E" }}
                      />

                      <CardContent className="card-boot-content-wrapper">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="boat-card-footer"
                        >
                          {translate(data?.boatTypes[1].name)} (
                          {addLeadingZero(data?.boatTypes[1].total)})
                        </Typography>
                        <BsArrowRightShort className="boat-sec-more-icon" />
                      </CardContent>
                    </Link>
                  </Card>
                </div>
              )}
              {data?.boatTypes[2] && (
                <div className="col-lg-3">
                  <Card
                    sx={{
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomRightRadius: "16px",
                      borderBottomLeftRadius: "16px",
                      boxShadow: "none",
                    }}
                    className="boot-card-content-wrapper"
                  >
                    <Link
                      to={`/boat/search-location/${data?.boatTypes[2]?.id}/${data?.boatTypes[2]?.slug}`}
                      className="link-wrapper"
                    >
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        // height="100%"
                        image={
                          data?.boatTypes[2].image !== ""
                            ? data?.boatTypes[2].image
                            : "/assets/icons/fastBoat2.png"
                        }
                        style={{ backgroundColor: "#8BC53E" }}
                      />
                      <CardContent className="card-boot-content-wrapper">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="boat-card-footer"
                        >
                          {translate(data?.boatTypes[2]?.name)} (
                          {addLeadingZero(data?.boatTypes[2]?.total)})
                        </Typography>
                        <BsArrowRightShort className="boat-sec-more-icon" />
                      </CardContent>
                    </Link>
                  </Card>
                </div>
              )}
              {data?.boatTypes[3] && (
                <div className="col-lg-3 ">
                  <Card
                    sx={{
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomRightRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      boxShadow: "none",
                    }}
                    className="boot-card-content-wrapper"
                  >
                    <Link
                      to={`/boat/search-location/${data?.boatTypes[3]?.id}/${data?.boatTypes[3]?.slug}`}
                      className="link-wrapper"
                    >
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        // height="80%"
                        image={
                          data?.boatTypes[3].image !== ""
                            ? data?.boatTypes[3].image
                            : "/assets/icons/fastBoat4.png"
                        }
                        style={{ backgroundColor: "#8BC53E" }}
                      />
                      <CardContent className="card-boot-content-wrapper">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="boat-card-footer"
                        >
                          {translate(data?.boatTypes[3].name)} (
                          {addLeadingZero(data?.boatTypes[3].total)})
                        </Typography>
                        <BsArrowRightShort className="boat-sec-more-icon" />
                      </CardContent>
                    </Link>
                  </Card>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BoatType;
