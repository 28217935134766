import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { Link } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";
import { AiOutlineCheck } from "react-icons/ai";
import "./AllBookedProperty.css";
import { useLanguage } from "../../utils/LanguageContext";
import { addLeadingZero } from "../../utils/addLeadingZero";
import DashboardSideNav from "../DashboardSideNav/DashboardSideNav";
import Loader from "../../Components/Loader";
import AddProperty from "../AddNewProperty/AddNewProperty";

function AllBookedProperty() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();
  const [activeTab, setActiveTab] = useState("all-booked-properties");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/booked-property/all");
      setData(res.data);
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  function getStatusBadge(status) {
    if (status === 0) {
      return (
        <span className="badge badge-warning">{translate("Pending")}</span>
      );
    } else if (status === 1) {
      return (
        <span className="badge badge-success">{translate("Success")}</span>
      );
    } else if (status === 2) {
      return <span className="badge badge-danger">{translate("Cancel")}</span>;
    } else if (status === 3) {
      return (
        <span className="badge badge-success">{translate("Approved")}</span>
      );
    } else if (status === 4) {
      return (
        <span className="badge badge-success">{translate("Rejected")}</span>
      );
    }
    return null;
  }

  async function handleStatusChange(id, status) {
    try {
      setLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `owner/booked-property/${id}/${status}`
      );
      setLoading(false);
      if (res.success) {
        Toaster("success", res?.message);
      } else {
        Toaster("error", "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "all-booked-properties":
        return (
          <div className="inner-container">
            <div className="table-responsive">
              <div className="table-wrapper">
                <table
                  id="mytable"
                  className="table table-bordred table-striped"
                >
                  <thead className="bookedProperty-table-th">
                    <th>{translate("S.N.")}</th>
                    <th>{translate("Name")}</th>
                    <th>{translate("Property Type")}</th>
                    <th>{translate("Location")}</th>
                    <th>{translate("Price")}</th>
                    <th>{translate("Discount")}</th>
                    <th>{translate("Discounted Price")}</th>
                    <th>{translate("Status")}</th>
                    <th>{translate("Action")}</th>
                  </thead>
                  <tbody>
                    {data?.booked_properties?.data?.map((row, index) => (
                      <tr key={index} className="bookedProperty-td-wrapper">
                        <td style={{ paddingTop: "18px" }}>
                          <div className="sr-no-bg">0{index + 1}</div>
                        </td>
                        <td>{row?.property.name}</td>
                        <td>{row?.property.property_type.name}</td>
                        <td>{row?.property.location.name}</td>
                        <td>
                          {data?.curr_sym}
                          {showAmount(row?.property?.price)}
                        </td>
                        <td>{showAmount(row?.property?.discount)}%</td>
                        <td>
                          {data?.curr_sym}
                          {showAmount(row?.total_price)}
                        </td>
                        <td>{getStatusBadge(row?.status)}</td>
                        <td>
                          {row?.status != 1 && (
                            <Link
                              className="btn btn-sm btn-primary m-1"
                              onClick={() =>
                                handleStatusChange(row?.id, "success")
                              }
                            >
                              <AiOutlineCheck />
                            </Link>
                          )}
                          {row?.status != 2 && (
                            <Link
                              className="btn btn-sm btn-danger m-1"
                              onClick={() =>
                                handleStatusChange(row?.id, "cancel")
                              }
                            >
                              {translate(" Cancel")}
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );

      case "add-property":
        return (
          <div>
            <AddProperty activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="container">

          <div className="mainContainer">
            <div className="allProperty-headingWrappers">
              <div className="allProperty-heading">
                {activeTab === "add-property" && (
                  <div className="tab-container d-flex gap-3 mt-5 pt-4">
                    <div
                      className={`tab  text-white fw-bold    ${
                        activeTab === "all-booked-properties" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("all-booked-properties")}
                    ></div>
                  </div>
                )}
                {activeTab === "all-booked-properties" && (
                  <div className="tab-container">
                    <h1>{translate(data?.type + " Booked Property")}</h1>
                    <p>
                      {translate("Total Booked Property")}{" "}
                      <span>
                        {addLeadingZero(data?.booked_properties?.total)}
                      </span>
                    </p>
                    <div
                      className={`tab  text-white fw-bold  ${
                        activeTab === "add-property" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("add-property")}
                    ></div>
                  </div>
                )}
              </div>
              <div className="">
                {activeTab === "add-property" && (
                  <div className="tab-container d-flex gap-3">
                    <div
                      className={`tab addProperty-addBtn text-white fw-bold    ${
                        activeTab === "all-booked-properties" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("all-booked-properties")}
                    >
                      {translate("All Properties")}
                    </div>
                  </div>
                )}
                {activeTab === "all-booked-properties" && (
                  <div className="tab-container d-flex gap-3">
                    <div
                      className={`tab addProperty-addBtn text-white fw-bold  ${
                        activeTab === "add-property" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("add-property")}
                    >
                      {translate("+ Add New Property")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {renderTabContent()}
          </div>
        </div>
      )}
    </>
  );
}

export default AllBookedProperty;
