import React from "react";
import CenterHeading from "../../Components/Heading/CenterHeading";
import { useSelector } from "react-redux";
import { useLanguage } from "../../utils/LanguageContext";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { BiLocationPlus } from "react-icons/bi";
import Wishlist from "../../Components/Wishlist";
import { showAmount } from "../../utils/showAmount";
import { useNavigate } from "react-router-dom";

function TravelDeals() {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const { data } = useSelector((state) => state.landing);

  const handlePropertyDetails = (item) => {
    navigate(`/accomodation/${item?.id}/${item.slug}/details`);
  };

  const handleBoatDetails = (item) => {
    navigate(`/boat/${item?.id}/${item.slug}/details`);
  };
  return (
    <div className="container">
      <CenterHeading
        h1={translate(data?.trip?.heading)}
        p={translate(data?.trip?.sub_heading)}
      />

      <div>
        <div className="row boat-wrapper class1">
          {data?.properties?.map((item, index) => (
            <div
              key={index + "p"}
              className="col-lg-4 weeklyTripBg p-1 px-2"
              style={{
                backgroundImage: `url("/assets/icons/weeklyTripBg.png")`,
              }}
            >
              <Card style={{ borderRadius: "10px" }}>
                <CardMedia>
                  <div
                    id={`carouselExampleIndicators_${index + "p"}`}
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators">
                      {item?.image?.map((image, i) => (
                        <button
                          type="button"
                          data-bs-target={`#carouselExampleIndicators_${
                            index + "p"
                          }`}
                          data-bs-slide-to={i}
                          className={i === 0 ? "active" : ""}
                          aria-current={i === 0 ? "true" : "false"}
                          key={i}
                        ></button>
                      ))}
                    </div>
                    <div className="carousel-inner">
                      {item?.image?.map((image, i) => (
                        <div
                          className={`carousel-item ${i === 0 ? "active" : ""}`}
                          key={i}
                        >
                          <img
                            src={image}
                            className="d-block w-100"
                            alt={`Slide ${i + 1}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <Wishlist id={item.id} type={"property"} />
                </CardMedia>
                <CardContent className="home-boat-card-content">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="top-dealCard-content-heading"
                  >
                    <h2>{translate(item.name)}</h2>
                    <h3>({item.total})</h3>
                  </Typography>
                  <Typography className="top-dealCard-content-discription">
                    <p>
                      <BiLocationPlus style={{ color: "#8BC53E" }} />
                      {translate(item.location)}
                    </p>
                  </Typography>
                  <Typography className="top-dealCard-content-button">
                    <h3>
                      {translate(data?.curr_sym)}
                      {item?.is_complete === 1
                        ? showAmount(item.price)
                        : showAmount(item.price1) +
                          " - " +
                          translate(data?.curr_sym) +
                          showAmount(item.price2)}{" "}
                      <span>{translate(item.label)}</span>
                    </h3>
                    <button onClick={() => handlePropertyDetails(item)}>
                      {translate("View Detail")}
                    </button>
                  </Typography>
                </CardContent>
              </Card>
            </div>
          ))}
          {data?.boats?.length > 0 &&
            data?.boats?.map((item, index) => (
              <div
                key={index + "b"}
                className="col-lg-4 weeklyTripBg p-1 px-2"
                style={{
                  backgroundImage: `url("/assets/icons/weeklyTripBg.png")`,
                }}
              >
                <Card style={{ borderRadius: "10px" }}>
                  <CardMedia>
                    <div
                      id={`carouselExampleIndicators_${index + "b"}`}
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators">
                        {item.image.map((image, i) => (
                          <button
                            type="button"
                            data-bs-target={`#carouselExampleIndicators_${
                              index + "b"
                            }`}
                            data-bs-slide-to={i}
                            className={i === 0 ? "active" : ""}
                            aria-current={i === 0 ? "true" : "false"}
                            key={i}
                          ></button>
                        ))}
                      </div>
                      <div className="carousel-inner">
                        {item.image.map((image, i) => (
                          <div
                            className={`carousel-item ${
                              i === 0 ? "active" : ""
                            }`}
                            key={i}
                          >
                            <img
                              className="d-block w-100 home-page-slider"
                              src={
                                image !== ""
                                  ? image
                                  : "/assets/icons/shipList1.png"
                              }
                              alt={`Slide ${i + 1}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <Wishlist id={item.id} type={"boat"} />
                  </CardMedia>
                  <CardContent className="home-boat-card-content">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="top-dealCard-content-heading"
                    >
                      <h2>{translate(item.name)}</h2>
                      <h3>({item.total})</h3>
                    </Typography>
                    <Typography className="top-dealCard-content-discription">
                      <p>
                        <BiLocationPlus style={{ color: "#8BC53E" }} />
                        {translate(item.location)}
                      </p>
                    </Typography>
                    <Typography className="top-dealCard-content-button">
                      <h3>
                        {translate(data?.curr_sym)}
                        {showAmount(item.price)}{" "}
                        <span>{translate(item.label)}</span>
                      </h3>
                      <button onClick={() => handleBoatDetails(item)}>
                        {translate("View Detail")}
                      </button>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default TravelDeals;
