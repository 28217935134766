import React, { useEffect, useState} from 'react';
import { BiPaperclip, BiSmile } from 'react-icons/bi';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import makeHttpRequest from '../../../../utils/api';
import {Button} from 'react-bootstrap';
import OfferModal from '../../offerModal/OfferModal';
import { useLanguage } from '../../../../utils/LanguageContext';


function InputMessage  ({type,id,inputState,sidebar,selectedUser,setUserSentMessage,setAttachedFile, classes, isOfferModalOpen, setIsOfferModalOpen, currency}) {
    
    
    const {inputText, setInputText} = inputState;
    const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State to manage emoji picker visibility
    const { langData, fetchLangData, translate } = useLanguage();
    

    const handleSendMessage = async (text) => {
        if (!selectedUser) return;

        try {
          let text_data = {
            type,
            id,
            user:selectedUser,
            content:text
          };
          
          const res = await makeHttpRequest("POST", `owner/chat/message/store`, text_data);
          
          if(res.success){
            setShowEmojiPicker(false);
            // setConversations(res?.data?.chat);
            // setSelectedUser(res?.data?.chat[0]);
          } else if(!res.success){
          } else{
          }
        } catch (error) {
          console.error("Error is:", error);
        }
    
        // setChats(prevMessages => ({
        //   ...prevMessages,
        //   [selectedUser.id]: [...(prevMessage || []), newMessage]
        // }));
        setUserSentMessage(true); // Set the flag when the user sends a message
        setInputText('');
      };

      const handleInputChange = (e) => {
        setInputText(e.target.value);
      };
    
      const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setAttachedFile(file);
      };
    
      const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
      };
    
      const handleEmojiSelect = (emoji) => {
        setInputText(inputText + emoji.native); // Insert the selected emoji into the input text
      };

      
      // modal
      useEffect(() => {
        setShow(isOfferModalOpen);
      },[isOfferModalOpen]);
      const [show, setShow] = useState(isOfferModalOpen);

      const handleShow = () => setShow(true);
      const handleHide = () => {
        setShow(false);
        setIsOfferModalOpen(false);
      }
      return (<div className={sidebar ? `${classes['chat-input']} ${classes['inputIndex']}` : `${classes['chat-input']}`}>
       
                  <input
                    type="text"
                    placeholder="Type a message..."
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSendMessage(inputText);
                        setInputText('');
                      }
                    }}
                    value={inputText}
                    disabled={!selectedUser} // Disable input if no user selected
                    onChange={handleInputChange}
                  />
                  <div className={classes['buttons-container']}>
                    <div className={classes['buttons-SubContainer']}>
                      {/* emoji button  */}
                      {/* <i className="fa fa-smile-o fa-lg" onClick={toggleEmojiPicker}></i> */}
                      <BiSmile onClick={toggleEmojiPicker}/>
                      {showEmojiPicker && (
                        <div className={classes['emoji-container']}>
                        <Picker 
                        data={data}
                        previewPosition="none"
                        onEmojiSelect={handleEmojiSelect} />
                        </div>
                      )}
                      {/* attachment button  */}
                      {/* <label htmlFor="fileInput" className={classes['attachment-icon']}>
                        <BiPaperclip />
                      </label>
                      <input
                        id="fileInput"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                      /> */}
                      <Button variant="dark" onClick={handleShow}>{translate('Create an Offer')}</Button>
                      <OfferModal show={show} hide={handleHide} currency={currency} selectedUser={selectedUser} handleShow={handleShow}/>
                    </div>
                    {/* send button  */}
                    <button className={classes['send-button']}  onClick={() => handleSendMessage(inputText)} disabled={!selectedUser}>Send</button>
                    

                  </div>  
                </div>);
}
export default InputMessage;