import React, { useEffect, useState } from "react";
import classes from "./UserProfileEdit.module.css";
import Footer from "../../Components/Footer/Footer";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import Navbar from "../../Components/Navbar/Navbar";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";
import { CircularProgress, Stack } from "@mui/material";
import Toaster from "../../Components/Toaster";
import { addLeadingZero } from "../../utils/addLeadingZero";

function UserProfileEdit() {
  const [fullname, setFullname] = useState("Jane Cooper");
  const [email, setEmail] = useState("jane@example.com");
  const [phonenumber, setPhonenumber] = useState("+123456789");
  const [address, setAddress] = useState("");
  const [stat, setStat] = useState({
    total_tickets: "",
    total_booked: "",
    total_pending_review: "",
  });
  const { langData, fetchLangData, translate } = useLanguage();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await makeHttpRequest("GET", "user/profile-setting");
      setFullname(response?.data?.user?.name);
      setEmail(response?.data?.user?.email);
      setPhonenumber(response?.data?.user?.phone);
      setAddress(response?.data?.user?.address?.address);
      setStat({
        total_tickets: response?.data?.total_tickets,
        total_booked: response?.data?.total_booked,
        total_pending_review: response?.data?.total_pending_review,
      });
    } catch (error) {
      console.error("Error is:", error);
    } finally {
      setLoading(false);
    }
  };

  const saveData = async () => {
    setLoading(true);
    try {
      const response = await makeHttpRequest("POST", "user/profile-setting", {
        address,
      });
      Toaster("success", response?.data?.message);
    } catch (error) {
      console.error("Error is:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderStatCard = (label, value, imgSrc) => (
    <div className={` container ${classes.profileCard} `}>
      <div className={`container ${classes["profile-details"]}`}>
        <div className={` ${classes.ticketCards} mt-2 `}>
          <img src={imgSrc} alt={label} className={classes["profile-pic"]} />
        </div>
        <div className={`container ${classes.ticket_info} mt-4`}>
          <b>
            <span style={{ fontFamily: "Montserrat" }}>
              {addLeadingZero(value)}
            </span>
          </b>
          <p
            className={`${classes["p-tag-color"]} mb-0 mt-1`}
            style={{ fontFamily: "Montserrat" }}
          >
            {translate(label)}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Navbar />
      {loading && (
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}

      <div className="container my-5">
        {!loading && (
          <div className="row">
            <div className={`col-lg-3 col-md-3 col-12 ${classes.profileCard} `}>
              <div className={` container ${classes["user_profile_info"]}`}>
                <div className={` ${classes["profile-container"]} mt-2 `}>
                  <span className={classes["Save-icon"]}></span>
                </div>
                <div className={`container ${classes["profile_info"]} mt-3 `}>
                  <b style={{ fontFamily: "Montserrat" }}>{fullname}</b>
                  <p
                    className={classes["p-tag-color"]}
                    style={{ fontFamily: "Montserrat" }}
                  >
                    {email}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-12">
              <div className={classes.profilee}>
                {renderStatCard(
                  "Total Tickets",
                  stat.total_tickets,
                  "/assets/icons/totalTicket.png"
                )}
                {renderStatCard(
                  "Total Booked",
                  stat.total_booked,
                  "/assets/icons/totalBookedTicket.png"
                )}
                {renderStatCard(
                  "Pending Reviews",
                  stat.total_pending_review,
                  "/assets/icons/totalPendingReviews.png"
                )}
              </div>
            </div>
          </div>
        )}
        <div className="row mt-3">
          {!loading && (
            <>
              <div className="col-lg-3 col-md-3 col-12">
                <UserSideNavbar />
              </div>
              <div className="col-lg-9 col-md-12 col-12">
                <div className={classes["supportTicket-bg"]}>
                  <div
                    className="table-responsive"
                    style={{ borderRadius: "10px" }}
                  >
                    <div className={classes["supportTicket-wrapper"]}>
                      <form>
                        <p
                          className="h1 ml-4 my-3"
                          style={{ fontFamily: "Montserrat" }}
                        >
                          {translate("Personal Info")}
                        </p>
                        {[
                          {
                            label: "Full name",
                            value: fullname,
                            setter: setFullname,
                            type: "text",
                            disabled: true,
                          },
                          {
                            label: "Email address",
                            value: email,
                            setter: setEmail,
                            type: "email",
                            disabled: true,
                          },
                          {
                            label: "Phone number",
                            value: phonenumber,
                            setter: setPhonenumber,
                            type: "text",
                            disabled: true,
                          },
                          {
                            label: "Address",
                            value: address,
                            setter: setAddress,
                            type: "text",
                            disabled: false,
                          },
                        ].map(({ label, value, setter, type, disabled }) => (
                          <div className="form-group col" key={label}>
                            <b>
                              <label
                                htmlFor={label
                                  .replace(/\s+/g, "")
                                  .toLowerCase()}
                                className="col-sm-12 col-form-label"
                                style={{ fontFamily: "Montserrat" }}
                              >
                                {translate(label)}
                              </label>
                            </b>
                            <div className="col-sm-12 d-flex justify-content-between m-0 profile-input-fields">
                              <input
                                disabled={disabled}
                                type={type}
                                id={label.replace(/\s+/g, "").toLowerCase()}
                                onChange={(e) => setter(e.target.value)}
                                value={value}
                                style={{ fontFamily: "Montserrat" }}
                              />
                              {!disabled && (
                                <span
                                  className={` ${classes.anchorTagcolor} ml-2 mt-2 `}
                                  style={{
                                    fontFamily: "Montserrat",
                                    cursor: "pointer",
                                    background: "#45aaf0 !important",
                                    color: "#fff",
                                  }}
                                  onClick={saveData}
                                >
                                  {translate("Save")}
                                </span>
                              )}
                            </div>
                            <hr className="hr" />
                          </div>
                        ))}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UserProfileEdit;
