import React, { useRef, useState } from "react";
import "./ImageUploader.css";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";
import { BiUpload } from "react-icons/bi";

function ImageUploader({
  selectedImage,
  onImageChange,
  onChangeEvent,
  heightImg,
  old_images = '',
  handleOldImage,
  change_images = '',
  handleChangeImage = []
}) {
  const fileInputRef = useRef(null);
  const { langData, fetchLangData, translate } = useLanguage();
  const [image_height, setImageHeight] = useState(heightImg);
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      const img = new Image();
      img.src = imageUrl;
      img.onload = function () {
        onChangeEvent(selectedFile);
        onImageChange(imageUrl);
      };

      img.onerror = function () {
        alert("Error loading the image.");
        // Optionally clear the file input to allow re-selection
        // e.target.value = '';
      };
    } else {
      onChangeEvent(null);
      onImageChange(null);
    }
  };

  const removeImage = async (img = "") => {
    onChangeEvent(""); // Empty string instead of "/assets/icons/uploadIcon.png"
    onImageChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if (Array.isArray(old_images)) {
      const updatedImages = old_images.filter((image) => image !== img);
      handleOldImage(updatedImages);
    }
    // handleChangeImage(updatedImages);
    // console.log(updatedImages);
    // You can set the value in the input field if needed
    // document.querySelector('[name="old_images"]').value = updatedImages.join(',');
 


  };

  return (
    <div>
      {selectedImage ? (
        <div className="uploadimage-attachment no-padding">
          <div className="uploadimage-content">
            <div className="position-relative" style={{ height: image_height }}>
              <img
                src={selectedImage}
                alt="Selected img"
                style={{
                  width: "100%",
                  height: image_height,
                  objectFit: "cover",
                }}
              />
              <button
                className="btn-close uploadimage-closebtn"
                onClick={()=>removeImage(selectedImage)}
              ></button>
            </div>
          </div>
        </div>
      ) : (
        <div className="uploadimage-attachment" onClick={triggerFileInput}>
          <div className="uploadimage-content">
            <div>
              <BiUpload
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                }}
              />
            </div>
            <p>{translate("JEPG, JPG, PNG")}</p>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/png, image/jpg, image/jpeg, image/gif, image/webp, video/mp4"
              onChange={handleFileUpload}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageUploader;
