import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useLanguage } from '../../../../utils/LanguageContext';
import makeHttpRequest from '../../../../utils/api';

function Note({ show, handleClose, handleShow, chat_person_id, note_content }) {
  const { langData, fetchLangData, translate } = useLanguage();
  const [note, setNote] = useState(note_content);

  async function submitNote(){
    
    const res = await makeHttpRequest("POST", `owner/chat-info`,{note,chat_person_id});
    if(res?.success){
    }else{
        console.log(res?.message);
    }

  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{translate('Add Note')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <textarea type="text"
            onChange={(e) => setNote(e.target.value)}  name="note" className="form-control">{note_content?note_content:note}</textarea>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {translate('Close')}
        </Button>
        <Button variant="primary" onClick={submitNote}>
          {translate('Save changes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Note;
