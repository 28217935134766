import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from "../../utils/LanguageContext";
import { useDispatch, useSelector } from "react-redux";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import WorkHeading from "../../Components/Heading/WorkHeading";
import { Link, useNavigate } from "react-router-dom";
import ExtensionApi from "../../Components/ExtensionApi";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";

function TravelExperience() {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const { data } = useSelector((state) => state.landing);
  const [state, setState] = useState({ username: "", password: "" });
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    // Reset the form state when the component mounts
    setState({ username: "", password: "" });
    setIsCaptchaPassed(false);

    // Check if the user is already logged in
    const token = localStorage.getItem("bearerToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async () => {
    if (!isCaptchaPassed) {
      Toaster("warning", "Captcha Required");
      return;
    }

    const { username, password } = state;
    if (!username) {
      Toaster("info", "Enter username");
      return;
    }
    if (!password) {
      Toaster("info", "Enter password");
      return;
    }

    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "login", {
        username,
        password,
        user_type: "user",
      });
      setLoggedUser(res);
    } catch (error) {
      console.error("Login error:", error);
      Toaster("error", "Login failed");
    } finally {
      setLoading(false);
    }
  };

  const setLoggedUser = (res) => {
    if (res?.success) {
      localStorage.setItem("bearerToken", res.data.token);
      localStorage.setItem("userType", res.data.type);
      localStorage.setItem("userEmail", res.data.user?.email);
      dispatch(userLogged(res.data.type === "user" ? "user" : "owner"));
      setIsLoggedIn(true);
      navigate("/"); // Redirect to home page
    } else {
      Toaster("error", "Invalid credentials");
    }
  };
  return (
    <div className="container">
      <div className="row mt-lg-5 pt-lg-5">
        <div className="col-lg-7 col-md-12 col-sm-12">
          <WorkHeading
            h1={translate(data?.work?.title)}
            p={translate(data?.work?.heading)}
          />

          <div className="row ">
            {data?.works?.map((value, i) => (
              <div className="col-lg-6 col-md-6 col-12 mt-5">
                <div className="experience-detail-heading">
                  <h3 className="">{translate(value?.data_values?.step)}</h3>
                  <p className="fs-7">
                    {translate(value?.data_values?.description)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-lg-5 col-md-6 col-12">
          {isLoggedIn ? (
            <div
              className="experience-sec-Image"
              style={{
                backgroundImage: `url(${data?.work?.right_image}) `,
                width:"100%",
                height: "400px",
                backgroundSize: "cover", 
                backgroundPosition: "center", 
                backgroundRepeat:"no-repeat"
              }}
            ></div>
          ) : (
            <div className="login-form">
              <h3 className="text-center">{translate("Login")}</h3>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                <div className="login-email">
                  <label>{translate("Email")}</label>
                  <input
                    name="username"
                    type="text"
                    placeholder={translate("Enter User")}
                    ref={usernameRef}
                    value={state.username}
                    onChange={handleInput}
                    onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                    autoComplete="username"
                  />
                </div>

                <div className="login-password">
                  <label>{translate("Password")}</label>
                  <input
                    name="password"
                    type="password"
                    placeholder={translate("Enter your password")}
                    ref={passwordRef}
                    value={state.password}
                    onChange={handleInput}
                    onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                    autoComplete="current-password"
                  />
                </div>
                <div className="login-password mt-3 mb-3">
                  <ExtensionApi CaptchaPassed={setIsCaptchaPassed} />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  style={{
                    backgroundColor: "#8bc53e",
                    borderColor: "#8bc53e",
                    width: "100%",
                  }}
                >
                  {translate("Login")}
                </button>
              </form>
              <div className="signUp-link">
                <p>
                  {translate("Don't have an account?")}{" "}
                  <Link
                    style={{ fontSize: "16px", color: "#45AAF0" }}
                    to="/register"
                  >
                    {translate("Sign Up")}
                  </Link>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TravelExperience;
