import React, { useEffect, useState } from "react";
import "./HomePage.css";
import MainSearchEngine from "../../Components/MainSearchEngine/MainSearchEngine";
import Footer from "../../Components/Footer/Footer";
import makeHttpRequest from "../../utils/api";
import Navbar from "../../Components/Navbar/Navbar";
import { CircularProgress, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLanguage } from "../../utils/LanguageContext";
import { addData } from "./landingSlice";
import { title } from "../../utils/helpers";
import EventModal from "../../Components/EventModal/EventModal";
import BoatType from "../BoatesType/BoatType";
import { useCallback } from "react";
import TravelDeals from "../TravelDeals/TravelDeals";
import TravelExperience from "../TravelExperience/TravelExperience";
import HomeCarousel from "../HomeCarousel/HomeCarousel";
import PropertyType from "../PropertyType/PropertyType";

function LandingPage() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.landing);
  const { translate } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [setSelectedTypeLocation] = useState("property");

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "home");
      dispatch(addData(response.data));
      document.title = title();
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [data, fetchData]);

  // const handleLocationFn = async (id) => {
  //   navigate("/locations");
  // };

  // const handleLocationFn = async (id) => {
  //   setLoading(true);
  //   if (selected_type_location === "property") {
  //     const res = await makeHttpRequest(
  //       "GET",
  //       `search?type=${selected_type_location}&location=${id}`
  //     );
  //     navigate("/search", { state: res });
  //   } else {
  //     const res = await makeHttpRequest("GET", `boat?location=${id}`);
  //     navigate("/boat", { state: res });
  //   }
  //   setLoading(false);
  // };

  return (
    <>
      {loading && (
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && (
        <>
          <Navbar />
          <div className="container">
            <div className="main-heading">
              <EventModal />

              <h1>{translate(data?.banner?.data_values?.heading)}</h1>
              <p>{translate(data?.banner?.data_values?.sub_heading)}</p>
            </div>
            <div
              className="main-heading-background"
              style={{
                backgroundImage: `url("/assets/icons/mainHeadingBg.png")`,
              }}
            >
              <MainSearchEngine location_type={setSelectedTypeLocation} />
            </div>
          </div>
          <HomeCarousel />
          <PropertyType/>
          <BoatType />
          <TravelDeals />
          <TravelExperience />
          <div className="homePage-footer">
            <Footer />
          </div>
        </>
      )}
    </>
  );
}

export default LandingPage;
