import React from 'react';
var old_date = null;
var created_at = null;
function ChatDisplayDate({c}) {
  let showImage = false;
  if(old_date != null)
  old_date = old_date.split('T')[0];
  created_at = c?.created_at.split('T')[0];
  if(old_date !== created_at){
    showImage = true;
    old_date = created_at
  } else{
    showImage = false;
  };
  return (
  showImage &&
  (<span className="badge badge-outline-secondary text-dark text-center">{`${new Date(c?.created_at).getFullYear()}-${(new Date(c?.created_at).getMonth() + 1).toString().padStart(2, '0')}-${new Date(c?.created_at).getDate().toString().padStart(2, '0')}`}</span>)
);
}
export default ChatDisplayDate;