import React, { useState, useEffect, useRef } from "react";
import { MessageList, Avatar } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./UserChat.module.css";
import "../ChatStyle.css";
import Navbar from "../../../Components/Navbar/Navbar";
import UserSideNavbar from "../../../UserDashboardComponent/UserSideNavbar/UserSideNavbar";
import InputMessage from "./component/InputMessage";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useLanguage } from "../../../utils/LanguageContext";
import { CircularProgress, Stack } from "@mui/material";
import ChatDisplayDate from "./component/ChatDisplayDate";
import makeHttpRequest from "../../../utils/api";
import { showAmount } from "../../../utils/showAmount";
import { isDateExpired, pusherHelper, ucwords } from "../../../utils/helpers";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { MdClose, MdOutlineMenu } from "react-icons/md";

import Toaster from "../../../Components/Toaster";
import PaymentModal from "./PaymentModal";
import PreviewModal from "./PreviewModal";
import ConfirmModal from "./ConfirmModal";

let style = {};
let style2 = {};
const isMobile = window.innerWidth <= 767;
if (isMobile) {
  style = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
  style2 = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
} else {
  style = {
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
  style2 = {
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
}

const Chat = () => {
  const { item, id, slug } = useParams();
  const navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [inputIframe, setInputIframe] = useState(
    `${window.location.origin}/${item}/${id}/${slug}/details`
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [userSentMessage, setUserSentMessage] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  console.log("sidebar", sidebar);
  const sidebarRef = useRef(null);
  const { translate } = useLanguage();
  const [attachedFile, setAttachedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading_chat, setLoadingChat] = useState(false);
  const [conversations, setConversations] = useState();
  const [chats, setChats] = useState([]);
  const [logged_id, setLoggedIn] = useState();
  const [scroll_below, setScrollBelow] = useState();
  const [messages_limit, setMessagesLimit] = useState(0);

  // payment
  const [booked_id, setBookedId] = useState();
  const [booked_type, setBookedType] = useState();
  const [booked_payment_gateway, setBookedPaymentGateway] = useState();
  const [preview_data, setPreviewData] = useState();
  const [confirm_data, setConfirmData] = useState("");

  const [payment_open, setPaymentOpen] = useState(false);
  const handlePaymentClose = () => setPaymentOpen(false);

  const [currency, setCurrency] = useState();

  useEffect(() => {
    const fetchData = async () => {
      await getConversation(id, slug);
    };

    fetchData();

    window.Pusher = Pusher;
    const echo = new Echo({
      broadcaster: "pusher",
      key: pusherHelper("api_key"),
      cluster: pusherHelper("cluster"),
      forceTLS: true,
    });

    echo.channel("chat-channel").listen(".chat-event", (e) => {
      setChats((prevMessages) => [...prevMessages, e.message]);
    });
  }, []);

  async function getConversation(id, slug) {
    try {
      setLoadingChat(true);
      const res = await makeHttpRequest("POST", `user/chat/conversations`, {
        slug,
        id,
      });
      if (res.success) {
        setConversations(res?.data?.chat);
        setSelectedUser(res?.data?.chat[0]);
        handleMessageClick(res?.data?.chat[0]);
        setCurrency(res?.data?.curr_sym);
        setLoadingChat(false);
      } else if (!res.success) {
        navigate(inputIframe);
      } else {
        navigate(inputIframe);
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoadingChat(false);
    }
  }

  useEffect(() => {
    const fetchData = () => {
      document
        .querySelector("#chat-messages")
        ?.scrollTo(0, document.body.scrollHeight);
    };

    fetchData();
  }, [scroll_below]);
  const handleMessageClick = async (user, is_increment = true) => {
    setSelectedUser(user);
    // Get Message with this user
    if (user && user?.conversation_id !== 0) {
      try {
        setLoading(true);
        const res = await makeHttpRequest("POST", `user/chat/messages`, {
          slug,
          id,
          user,
          limit: messages_limit,
        });
        if (res.success) {
          setScrollBelow(user);
          if (is_increment) setMessagesLimit(res?.data?.count);
          setChats(res?.data?.messages);
          setLoggedIn(res?.data?.logged_id);
        } else if (!res.success) {
          navigate(inputIframe);
        } else {
          navigate(inputIframe);
        }
      } catch (error) {
        console.error("Error is:", error);
        setLoading(false);
      }
    }
    setSidebar(false);
  };

  const handleHamburgerClick = () => {
    setSidebar((prev) => !prev);
  };

  const backdropHandle = () => {
    setSidebar(false);
  };

  function loadMoreMessages() {
    setMessagesLimit(messages_limit + 20);
    handleMessageClick(selectedUser);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const handleSetupPayment = async (id, type) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "user/custom-payment", {
        id: id,
        type: type,
      });
      setLoading(false);
      if (res.success) {
        setBookedPaymentGateway(res.data);
        setPaymentOpen(true);
        setBookedId(id);
        setBookedType(type);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container my-4">
        <div className="row">
          <div className="col-lg-3 col-md-3 d-none d-lg-block">
            <UserSideNavbar />
          </div>
          <div className="col-lg-9 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              {/* payment modal */}
              <PaymentModal />

              {/* Preview Modal */}

              <PreviewModal />

              {/* Confirm Modal */}

              <ConfirmModal />
            </div>
            {loading_chat ? (
              <Stack height="80vh" alignItems="center" justifyContent="center">
                <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
              </Stack>
            ) : (
              <>
                <div className={classes["chat-container"]}>
                  {/* <div className="row">
                    <div
                      className={`col-lg-3 col-md-3 ${
                        sidebar ? "d-block" : "d-none"
                      } d-md-block`}
                    >
                      <div
                        ref={sidebarRef}
                        className={
                          sidebar
                            ? `${classes["sidebar"]} ${classes["sidebarMobile"]}`
                            : classes["sidebar"]
                        }
                      >
                        <h4>{translate("Messages")}</h4>
                        <hr />
                        <ul className={classes["chat-ul"]}>
                          {conversations?.map((conversation) => (
                            <li
                              key={conversation?.id}
                              className={`${classes["userId"]} ${
                                selectedUser &&
                                selectedUser.id === conversation?.id
                                  ? classes["selected"]
                                  : ""
                              }`}
                              onClick={() => handleMessageClick(conversation)}
                            >
                              <Avatar
                                src={conversation?.image}
                                alt={`Profile ${conversation?.name}`}
                                className={classes["avatar"]}
                                size="small"
                              />
                              {conversation?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div
                      className={`col-lg-9 col-md-9 ${
                        sidebar ? "col-sm-9" : "col-sm-12"
                      }`}
                    >
                      <div className={classes["rightbar"]}>
                        <div className="chat-section-background">
                          <div className="d-flex justify-content-between align-item-center p-3">
                            <h2 className={classes["selectedUser"]}>
                              <Avatar
                                src={selectedUser?.image}
                                alt={`Profile ${selectedUser?.name}`}
                                size="small"
                              />
                              {selectedUser?.name}
                            </h2>

                            <i
                              className={`mr-3 ${classes["hamburger"]} ${
                                sidebar ? "open" : ""
                              }`}
                              onClick={handleHamburgerClick}
                              style={{ cursor: "pointer" }} // Add pointer cursor for better UX
                            >
                              {sidebar ? <MdClose /> : <MdOutlineMenu />}
                            </i>
                          </div>
                        </div>
                        <div className={classes["chatWrapper"]}>
                          <div
                            className={classes["chat-messages"]}
                            id="chat-messages"
                          >
                            {chats && chats?.length > 0 && (
                              <div className="text-center">
                                <span
                                  className={`badge badge-primary ${classes["my-bdg"]}`}
                                  onClick={loadMoreMessages}
                                >
                                  {translate("Load More Messages")}
                                </span>
                              </div>
                            )}
                            {chats?.map((c) => (
                              <>
                                <ChatDisplayDate c={c} />
                                {c?.type == "message" && (
                                  <MessageList
                                    className={classes["message-list"]}
                                    lockable={true}
                                    toBottomHeight={"100%"}
                                    dataSource={
                                      [
                                        {
                                          position:
                                            c?.sender_id == logged_id
                                              ? "right"
                                              : "left",
                                          type: "text",
                                          text: c?.content,
                                          date: new Date(c?.created_at),
                                          dateString: `${new Date(
                                            c?.created_at
                                          ).getHours()}:${new Date(
                                            c?.created_at
                                          )
                                            .getMinutes()
                                            .toString()
                                            .padStart(2, "0")}${
                                            new Date(
                                              c?.created_at
                                            ).getHours() >= 12
                                              ? "PM"
                                              : "AM"
                                          }`,
                                        },
                                      ] || []
                                    }
                                  />
                                )}
                                {c?.type == "item" && (
                                  <div className="card">
                                    <div className="card-header chat-boat">
                                      <h3>
                                        <div className={classes["card-head"]}>
                                          <span>
                                            {ucwords(
                                              JSON.parse(c?.content)?.item
                                            )}
                                          </span>
                                          {JSON.parse(c?.content)?.discount >
                                            0.0 && (
                                            <span className="badge badge-success">
                                              {translate("Discount")} :{" "}
                                              {showAmount(
                                                JSON.parse(c?.content)?.discount
                                              )}
                                              %
                                            </span>
                                          )}
                                        </div>
                                      </h3>
                                      {JSON.parse(c?.content)?.url && (
                                        <div>
                                          (
                                          <Link
                                            to={JSON.parse(c?.content)?.url}
                                            target="_blank"
                                          >
                                            {JSON.parse(c?.content)?.url}
                                          </Link>
                                          )
                                        </div>
                                      )}
                                    </div>
                                    <div className="card-body">
                                      <table className="table table-striped">
                                        <tr>
                                          <th>
                                            {translate("Inquiry Boat Name")} (
                                            {JSON.parse(c?.content)?.days}{" "}
                                            {translate("Days")})
                                          </th>
                                          <td>
                                            {JSON.parse(c?.content)?.name}
                                          </td>
                                        </tr>

                                        <tr>
                                          <th>{translate("Boat Check-in")}</th>
                                          <td>
                                            {JSON.parse(c?.content)?.start}
                                          </td>
                                        </tr>

                                        <tr>
                                          <th>{translate("Boat Check-out")}</th>
                                          <td>{JSON.parse(c?.content)?.end}</td>
                                        </tr>

                                        <tr>
                                          <th>{translate("Adult/Child")}</th>
                                          <td>
                                            {JSON.parse(c?.content)?.adult}-
                                            {JSON.parse(c?.content)?.child}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                )}
                                {c?.type == "offer" && (
                                  <div className="card">
                                    <div className="card-header">
                                      <h3>
                                        <div className={classes["card-head"]}>
                                          <span>
                                            {ucwords("custom " + c?.type)}
                                          </span>
                                        </div>
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <div
                                        style={{
                                          border: "1px solid grey",
                                          padding: "5px",
                                        }}
                                      >
                                        <div>
                                          <b>
                                            {translate("Offer Description")}:
                                          </b>
                                        </div>
                                        <div
                                          className={classes["styled-line"]}
                                        ></div>
                                        <div style={{ marginTop: "10px" }}>
                                          {JSON.parse(c?.content)?.offer}
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            justifyContent: "space-around",
                                          }}
                                        >
                                          <b>{translate("Check In")} </b>{" "}
                                          {JSON.parse(c?.content)?.date_from}
                                        </div>
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            justifyContent: "space-around",
                                          }}
                                        >
                                          <b>{translate("Check Out")} </b>{" "}
                                          {JSON.parse(c?.content)?.date_to}
                                        </div>
                                      </div>
                                      <div>
                                        <div style={{ marginTop: "10px" }}>
                                          <b>{translate("Offer Services")}:</b>
                                        </div>
                                      </div>
                                      <div className={classes["center-bill"]}>
                                        <table
                                          className={`table table-striped ${classes["bill-width-50"]}`}
                                        >
                                          <tbody>
                                            {JSON.parse(
                                              c?.content
                                            )?.services.map((item, index) => (
                                              <tr
                                                className={`text-center ${classes["offer-row-height"]}`}
                                              >
                                                <th>
                                                  {item["service_title"] &&
                                                    translate(
                                                      ucwords(
                                                        item["service_title"]
                                                      )
                                                    )}
                                                </th>
                                                <td>
                                                  {item["service_price"] &&
                                                    currency +
                                                      translate(
                                                        item["service_price"]
                                                      )}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                          <tfoot>
                                            <tr
                                              className={`text-center ${classes["offer-row-height"]}`}
                                            >
                                              <th>{translate("Boat Rent")}</th>
                                              <th>
                                                {currency +
                                                  JSON.parse(c?.content)
                                                    ?.base_price}
                                              </th>
                                            </tr>
                                            <tr
                                              className={`text-center ${classes["offer-row-height"]}`}
                                            >
                                              <th>
                                                {translate("Total Price")}
                                              </th>
                                              <th>
                                                {currency +
                                                  JSON.parse(c?.content)
                                                    ?.total_price}
                                              </th>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-end">
                                      <button
                                        type="button"
                                        disabled={
                                          JSON.parse(c?.content)?.offer_setting
                                            ?.expire &&
                                          isDateExpired(
                                            c?.created_at,
                                            JSON.parse(c?.content)
                                              ?.offer_setting?.expire
                                          )
                                        }
                                        onClick={() =>
                                          handleSetupPayment(c?.id, "boat")
                                        }
                                        className="btn btn-secondary m-1"
                                      >
                                        {translate("Accept Offer")}
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                            {conversations && conversations.length > 0 ? (
                              <InputMessage
                                item={item}
                                id={id}
                                inputState={{ inputText, setInputText }}
                                inputIframeState={{
                                  inputIframe,
                                  setInputIframe,
                                }}
                                sidebar={sidebar}
                                selectedUser={selectedUser}
                                setUserSentMessage={setUserSentMessage}
                                setAttachedFile={setAttachedFile}
                                chats={chats}
                                setChats={setChats}
                                classes={classes}
                              />
                            ) : (
                              <h4 className="text-center fw-bold">
                                {translate("No Chat found")}
                              </h4>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div
                  ref={sidebarRef}
                  className={
                    sidebar
                      ? `${classes["sidebar"]} ${classes["sidebarMobile"]}`
                      : classes["sidebar"]
                  }
                >
                  <h4>{translate("Messages")}</h4>
                  <hr />
                  <ul className={classes["chat-ul"]}>
                    {conversations?.map((conversation) => (
                      <li
                        key={conversation?.id}
                        className={`${classes["userId"]} ${
                          selectedUser && selectedUser.id === conversation?.id
                            ? classes["selected"]
                            : ""
                        }`}
                        onClick={() => handleMessageClick(conversation)}
                      >
                        <Avatar
                          src={conversation?.image}
                          alt={`Profile ${conversation?.name}`}
                          className={classes["avatar"]}
                          size="small"
                        />
                        {conversation?.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={classes["rightbar"]}>
                  <div className="chat-section-background">
                    <div className="d-flex justify-content-between align-item-center p-3">
                      <h2 className={classes["selectedUser"]}>
                        <Avatar
                          src={selectedUser?.image}
                          alt={`Profile ${selectedUser?.name}`}
                          size="small"
                        />
                        {selectedUser?.name}
                      </h2>

                      <i
                        className={`mr-3 ${classes["hamburger"]} ${
                          sidebar ? "open" : ""
                        }`}
                        onClick={handleHamburgerClick}
                        style={{ cursor: "pointer" }} // Add pointer cursor for better UX
                      >
                        {sidebar ? <MdClose /> : <MdOutlineMenu />}
                      </i>
                    </div>
                  </div>
                  <div className={classes["chatWrapper"]}>
                    <div
                      className={classes["chat-messages"]}
                      id="chat-messages"
                    >
                      {chats && chats?.length > 0 && (
                        <div className="text-center">
                          <span
                            className={`badge badge-primary ${classes["my-bdg"]}`}
                            onClick={loadMoreMessages}
                          >
                            {translate("Load More Messages")}
                          </span>
                        </div>
                      )}
                      {chats?.map((c) => (
                        <>
                          <ChatDisplayDate c={c} />
                          {c?.type == "message" && (
                            <MessageList
                              className={classes["message-list"]}
                              lockable={true}
                              toBottomHeight={"100%"}
                              dataSource={
                                [
                                  {
                                    position:
                                      c?.sender_id == logged_id
                                        ? "right"
                                        : "left",
                                    type: "text",
                                    text: c?.content,
                                    date: new Date(c?.created_at),
                                    dateString: `${new Date(
                                      c?.created_at
                                    ).getHours()}:${new Date(c?.created_at)
                                      .getMinutes()
                                      .toString()
                                      .padStart(2, "0")}${
                                      new Date(c?.created_at).getHours() >= 12
                                        ? "PM"
                                        : "AM"
                                    }`,
                                  },
                                ] || []
                              }
                            />
                          )}
                          {c?.type == "item" && (
                            <div className="card">
                              <div className="card-header chat-boat">
                                <h3>
                                  <div className={classes["card-head"]}>
                                    <span>
                                      {ucwords(JSON.parse(c?.content)?.item)}
                                    </span>
                                    {JSON.parse(c?.content)?.discount > 0.0 && (
                                      <span className="badge badge-success">
                                        {translate("Discount")} :{" "}
                                        {showAmount(
                                          JSON.parse(c?.content)?.discount
                                        )}
                                        %
                                      </span>
                                    )}
                                  </div>
                                </h3>
                                {JSON.parse(c?.content)?.url && (
                                  <div>
                                    (
                                    <Link
                                      to={JSON.parse(c?.content)?.url}
                                      target="_blank"
                                    >
                                      {JSON.parse(c?.content)?.url}
                                    </Link>
                                    )
                                  </div>
                                )}
                              </div>
                              <div className="card-body">
                                <table className="table table-striped">
                                  <tr>
                                    <th>
                                      {translate("Inquiry Boat Name")} (
                                      {JSON.parse(c?.content)?.days}{" "}
                                      {translate("Days")})
                                    </th>
                                    <td>{JSON.parse(c?.content)?.name}</td>
                                  </tr>

                                  <tr>
                                    <th>{translate("Boat Check-in")}</th>
                                    <td>{JSON.parse(c?.content)?.start}</td>
                                  </tr>

                                  <tr>
                                    <th>{translate("Boat Check-out")}</th>
                                    <td>{JSON.parse(c?.content)?.end}</td>
                                  </tr>

                                  <tr>
                                    <th>{translate("Adult/Child")}</th>
                                    <td>
                                      {JSON.parse(c?.content)?.adult}-
                                      {JSON.parse(c?.content)?.child}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          )}
                          {c?.type == "offer" && (
                            <div className="card">
                              <div className="card-header">
                                <h3>
                                  <div className={classes["card-head"]}>
                                    <span>{ucwords("custom " + c?.type)}</span>
                                  </div>
                                </h3>
                              </div>
                              <div className="card-body">
                                <div
                                  style={{
                                    border: "1px solid grey",
                                    padding: "5px",
                                  }}
                                >
                                  <div>
                                    <b>{translate("Offer Description")}:</b>
                                  </div>
                                  <div className={classes["styled-line"]}></div>
                                  <div style={{ marginTop: "10px" }}>
                                    {JSON.parse(c?.content)?.offer}
                                  </div>
                                </div>
                                <div>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      display: "flex",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <b>{translate("Check In")} </b>{" "}
                                    {JSON.parse(c?.content)?.date_from}
                                  </div>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      display: "flex",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <b>{translate("Check Out")} </b>{" "}
                                    {JSON.parse(c?.content)?.date_to}
                                  </div>
                                </div>
                                <div>
                                  <div style={{ marginTop: "10px" }}>
                                    <b>{translate("Offer Services")}:</b>
                                  </div>
                                </div>
                                <div className={classes["center-bill"]}>
                                  <table
                                    className={`table table-striped ${classes["bill-width-50"]}`}
                                  >
                                    <tbody>
                                      {JSON.parse(c?.content)?.services.map(
                                        (item, index) => (
                                          <tr
                                            className={`text-center ${classes["offer-row-height"]}`}
                                          >
                                            <th>
                                              {item["service_title"] &&
                                                translate(
                                                  ucwords(item["service_title"])
                                                )}
                                            </th>
                                            <td>
                                              {item["service_price"] &&
                                                currency +
                                                  translate(
                                                    item["service_price"]
                                                  )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr
                                        className={`text-center ${classes["offer-row-height"]}`}
                                      >
                                        <th>{translate("Boat Rent")}</th>
                                        <th>
                                          {currency +
                                            JSON.parse(c?.content)?.base_price}
                                        </th>
                                      </tr>
                                      <tr
                                        className={`text-center ${classes["offer-row-height"]}`}
                                      >
                                        <th>{translate("Total Price")}</th>
                                        <th>
                                          {currency +
                                            JSON.parse(c?.content)?.total_price}
                                        </th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                              <div className="card-footer d-flex justify-content-end">
                                <button
                                  type="button"
                                  disabled={
                                    JSON.parse(c?.content)?.offer_setting
                                      ?.expire &&
                                    isDateExpired(
                                      c?.created_at,
                                      JSON.parse(c?.content)?.offer_setting
                                        ?.expire
                                    )
                                  }
                                  onClick={() =>
                                    handleSetupPayment(c?.id, "boat")
                                  }
                                  className="btn btn-secondary m-1"
                                >
                                  {translate("Accept Offer")}
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                      {conversations && conversations.length > 0 ? (
                        <InputMessage
                          item={item}
                          id={id}
                          inputState={{ inputText, setInputText }}
                          inputIframeState={{ inputIframe, setInputIframe }}
                          sidebar={sidebar}
                          selectedUser={selectedUser}
                          setUserSentMessage={setUserSentMessage}
                          setAttachedFile={setAttachedFile}
                          chats={chats}
                          setChats={setChats}
                          classes={classes}
                        />
                      ) : (
                        <h4 className="text-center fw-bold">
                          {translate("No Chat found")}
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
                  {sidebar && (
                    <div
                      className={` overlay active`}
                      onClick={backdropHandle}
                    ></div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
