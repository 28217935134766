import React, { useState } from "react";
import "./OpenTicket.css";
import { Link } from "react-router-dom";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import Loader from "../../Components/Loader";

function OpenTicket() {
  const [image, setImage] = useState(null);
  const [ori_image, setOriImage] = useState(null);
  const { langData, fetchLangData, translate } = useLanguage();

  let initialState = {
    name: "",
    email: "",
    subject: "",
    priority: "",
    image: "",
    message: "",
  };
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);

  const handleSubmit = async () => {
    if (!state.name) {
      Toaster("info", "Enter Name");
      return;
    }
    if (!state.email) {
      Toaster("info", "Enter Email");
      return;
    }
    if (!state.subject) {
      Toaster("info", "Enter Subject");
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", state.name);
      if (ori_image) {
        formData.append("image", ori_image);
      }
      formData.append("email", state.email);
      formData.append("priority", state.priority);
      formData.append("subject", state.subject);
      formData.append("message", state.message);

      // Send a POST request to the server to submit the data
      const res = await makeHttpRequest(
        "POST",
        "owner/ticket/create",
        formData
      );
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  return (
    <>
     {loading && <Loader />}
    {!loading && (

    <div className="container">
      <div className="openTicket-heading">
        <h1>{translate("Open New Support Ticket")}</h1>
      </div>
      <div className="openTicket-contentBg">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12 mt-3 openTicket-name-inputs">
            <label className="openTicket-inputLabel">
              {translate("Name")}
              <span>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              placeholder={translate("Enter Name")}
              value={state.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-12 mt-3 openTicket-name-inputs">
            <label className="openTicket-inputLabel">
              {translate("Email Address")}
              <span>*</span>
            </label>
            <input
              type="email"
              className="form-control"
              required
              placeholder={translate("Enter Email")}
              value={state.email}
              onChange={(e) => setState({ ...state, email: e.target.value })}
            />
          </div>

          <div className="col-lg-6 col-md-12 col-12 mt-5 openTicket-inputs">
            <label className="openTicket-inputLabel">
              {translate("Subject")}
              <span>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              placeholder={translate("Enter Subject")}
              value={state.subject}
              onChange={(e) => setState({ ...state, subject: e.target.value })}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-12 mt-5 openTicket-inputs">
            <label className="openTicket-inputLabel">
              {translate("Priority")}
              <span>*</span>
            </label>
            <div className="openTicket-dropdown">
              <select
                placeholder={translate("Enter Subject")}
                value={state.priority}
                onChange={(e) =>
                  setState({ ...state, priority: e.target.value })
                }
              >
                <option>{translate("Select One")}</option>
                <option value={1}>{translate("High")}</option>
                <option value={2}>{translate("Medium")}</option>
                <option value={3}>{translate("Low")}</option>
              </select>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-12 mt-5 openTicket-inputs">
            <label className="openTicket-inputLabel">
              {translate("Attachments")}
            </label>
            <ImageUploader
              selectedImage={image}
              onImageChange={setImage}
              onChangeEvent={setOriImage}
            />
          </div>

          <div className="col-lg-6 col-md-12 col-12 mt-5 openTicket-message-input">
            <label className="openTicket-inputLabel">
              {translate("Message")}
            </label>
            <textarea
              className="form-control"
              placeholder={translate("Enter Message")}
              value={state.message}
              onChange={(e) => setState({ ...state, message: e.target.value })}
            >
              {state.message}
            </textarea>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="openTicket-submitBtn">
              <button type="button" onClick={handleSubmit}>
                {translate("Submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
    </>
  );
}

export default OpenTicket;
