import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./MainSearchEngine.css";
import { Box, Button, styled } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import makeHttpRequest from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";
import { Link } from "react-router-dom";

const RootStyle = styled(Box)({
  padding: "20px",
  boxShadow: "0px 40px 64px -32px rgba(15, 15, 15, 0.10)",
  backdropFilter: "blur(16px)",
  borderRadius: "0px 0px 20px 20px",
});
const BtnAccom = styled(Button)({
  textTransform: "none",
  background: "#45aaf0 !important",
  height: "54px",
});

function MainSearchEngine(props = "") {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();

  const [state, setState] = useState({
    location: "",
    adults: 0,
    childs: 0,
    boat_type: "",
  });
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date().setDate(new Date().getDate() + 1),
      key: "selection",
    },
  ]);

  // filter
  const [locations, setLocations] = useState([]);
  const [boat_types, setBoatTypes] = useState([]);
  const [type, setType] = useState("property");

  useEffect(() => {
    fetchData();
    getBoatType();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "all-locations");
      setLocations(response.data.locations);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const getBoatType = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "all-boat-types");
      setBoatTypes(response.data.boatTypes);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelect = (ranges) => {
    // Update the date range when a user selects dates
    setDateRange([ranges.selection]);
    setShowDateRangePicker(false); // Close the DateRangePicker after selecting
  };

  const toggleDateRangePicker = () => {
    setShowDateRangePicker(!showDateRangePicker);
  };

  const formatDate = (arg) => {
    const date = new Date(arg);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    return formattedDate;
  };

  const handleSubmitSearch = async () => {
    try {
      const { location, adults, childs, boat_type } = state;
      const { startDate, endDate } = dateRange[0];
      let start = formatDate(startDate);
      let end = formatDate(endDate);
      setLoading(true);
      if (type === "property") {
        const res = await makeHttpRequest(
          "GET",
          `search?type=${type}&location=${location}&start=${start}&end=${end}&adult=${adults}&child=${childs}`
        );
        if (window.location.pathname == "/search") {
          props.accom(res.data);
        } else {
          navigate("/search", { state: res });
        }
      } else {
        const res = await makeHttpRequest(
          "GET",
          `boat?boatType=${boat_type}&location=${location}&start=${start}&end=${end}&adult=${adults}&child=${childs}`
        );

        navigate("/boat", { state: res });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handleSearchCategory = (para) => {
    if (typeof props.location_type === "function") {
      props.location_type(para);
    }
    setType(para);
  };

  // function doesUrlStartWith(url, prefix) {
  //   return url.startsWith(prefix);
  // }
  return (
    <RootStyle>
      <Box sx={{ mb: 2 }}>
        <nav className="site-navigation text-right" role="navigation">
          <ul className="search-menu">
            <li
              className={type === "property" ? "active" : ""}
              onClick={() => handleSearchCategory("property")}
            >
              <span
                style={{ cursor: "pointer" }}
                className="nav-link main-links-wrapper"
              >
                <img src="/assets/icons/acommodationIcon.png" alt="..." />{" "}
                {translate("Accommodations")}
              </span>
            </li>
            <li className="vertical-line"></li>
            <li
              className={type === "boat" ? "active" : ""}
              onClick={() => handleSearchCategory("boat")}
            >
              <span
                style={{ cursor: "pointer" }}
                className="nav-link main-links-wrapper"
              >
                <img src="/assets/icons/boatIcon.png" alt="..." />{" "}
                {translate("Boat")}
              </span>
            </li>
          </ul>
        </nav>
      </Box>

      <Grid container spacing={3}>
        <Grid item md={type === "boat" ? 2 : 3} sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel>{translate("Location")}</InputLabel>
            <Select
              variant="outlined"
              label={`${translate("Location")}`}
              name="location"
              defaultValue=""
              value={state.location ? state.location : props.locationId}
              onChange={handleInput}
            >
              {locations?.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item.id}
                  selected={props.locationId ? props.locationId == item.id : ""}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div>
            <TextField
              fullWidth
              variant="outlined"
              label={`${translate("Checkin")} ${translate("-")} ${translate(
                "Checkout"
              )}`}
              value={`${formatDate(dateRange[0].startDate)} - ${formatDate(
                dateRange[0].endDate
              )}`}
              onClick={toggleDateRangePicker}
              InputProps={{
                readOnly: true,
              }}
            />
            {showDateRangePicker && (
              <DateRangePicker
                localeText={{ start: "Check-in", end: "Check-out" }}
                ranges={[dateRange[0]]}
                onChange={handleSelect}
              />
            )}
          </div>
        </Grid>

        {type === "boat" && (
          <Grid item md={type === "boat" ? 2 : 3} sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel>{translate("Boat Type")}</InputLabel>
              <Select
                variant="outlined"
                label={translate("Boat Type")}
                name="Boat Type"
                defaultValue=""
                // value={props.locationId ? props.locationId : ""}
                value={state.boat_type}
                onChange={(e) =>
                  setState({ ...state, boat_type: e.target.value })
                }
              >
                {boat_types?.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item.id}
                    // selected={
                    //   props.locationId ? props.locationId == item.id : ""
                    // }
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item md={type === "boat" ? 1.5 : 1.5} sm={6} xs={6}>
          <TextField
            fullWidth
            label={`${translate("Adult")}`}
            variant="outlined"
            name="adults"
            value={state.adults}
            onChange={handleInput}
          />
        </Grid>

        <Grid item md={type === "boat" ? 1.5 : 1.5} sm={6} xs={6}>
          <TextField
            fullWidth
            label={`${translate("Child")}`}
            variant="outlined"
            name="childs"
            value={state.childs}
            onChange={handleInput}
          />
        </Grid>

        <Grid item md={type === "boat" ? 2 : 3} sm={6} xs={12}>
          <BtnAccom
            fullWidth
            variant="contained"
            sx={{ fontSize: { xs: "14px", sm: "14px", md: "16px" } }}
            onClick={handleSubmitSearch}
          >
            <img
              style={{ marginRight: "10px" }}
              src="/assets/icons/Vector.png"
              alt="..."
            />
            {translate(
              "Show " + (type === "property" ? "Accomodation" : "Boat")
            )}
          </BtnAccom>
        </Grid>
      </Grid>
    </RootStyle>
  );
}

export default MainSearchEngine;
