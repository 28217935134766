import React, { useRef, useState } from "react";
import makeHttpRequest from "../../utils/api";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import Toaster from "../../Components/Toaster";
import { useDispatch } from "react-redux";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import ExtensionApi from "../../Components/ExtensionApi";
import RegisterImage from "../../Components/RegisterImage";
import { GoogleLogin } from "@react-oauth/google";
import { useLanguage } from "../../utils/LanguageContext";
import Navbar from "../../Components/Navbar/Navbar";

function OwnerLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });
  const { translate } = useLanguage();
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const validateFields = () => {
    let valid = true;
    const newErrors = { username: "", password: "" };

    if (!state.username) {
      newErrors.username = translate("Enter username");
      valid = false;
    }

    if (!state.password) {
      newErrors.password = translate("Enter password");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleLogin = async () => {
    // Step 1: Validate fields
    const isValid = validateFields();
    if (!isValid) return; // Exit if validation fails

    // Step 2: Check CAPTCHA
    if (!isCaptchaPassed) {
      Toaster("warning", translate("Captcha Required"));
      return;
    }

    // Step 3: Proceed with login if validation and CAPTCHA are both passed
    try {
      setLoading(true);
      const data = {
        username: state.username,
        password: state.password,
        user_type: "owner",
      };
      const res = await makeHttpRequest("POST", "login", data);
      setLoggedUser(res);
      setLoading(false);
    } catch (error) {
      console.error("Error is :", error);
      setLoading(false);
    }
  };

  const handleEnter = (name) => {
    if (name === "username") {
      if (!state.username) {
        usernameRef.current.focus();
      } else if (state.username && !state.password) {
        passwordRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    } else {
      if (!state.password) {
        passwordRef.current.focus();
      } else if (!state.username && state.password) {
        usernameRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    }
  };

  const setLoggedUser = (res) => {
    if (res.success === true) {
      localStorage.setItem("bearerToken", res?.data?.token);
      localStorage.setItem("userType", res?.data?.type);
      localStorage.setItem("userEmail", res?.data?.user?.email);

      if (res?.data?.type === "user") {
        dispatch(userLogged("user"));
      } else {
        dispatch(userLogged("owner"));
      }
      navigate("/");
    } else {
      Toaster("error", "Invalid credentials");
    }
  };

  return (
    <>
      <Navbar />
      <div className="px-5 py-3">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 login-content-wrapper">
            <div className="login-heading">
              <img src="/assets/icons/companyLogo.png" alt="..." />
              <div className="loginHeading-topIcon">
                <img src="/assets/icons/aa1.png" alt="..." />
              </div>
              <div className="loginHeading-bottomIcon">
                <img src="/assets/icons/aa2.jpeg" alt="..." />
              </div>
              <h1>{translate("Welcome to CAPRIEASY")}</h1>

              <div className="login-email">
                <label>{translate("Email")}</label>
                <input
                  name="username"
                  type="text"
                  placeholder={translate("Enter User")}
                  ref={usernameRef}
                  value={state.username}
                  onChange={handleInput}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEnter("username");
                    }
                  }}
                />
                <p
                  className={`error-message ${errors.username ? "active" : ""}`}
                >
                  {errors.username}
                </p>
              </div>
              <div className="login-password">
                <label>{translate("Password")}</label>
                <input
                  name="password"
                  type="password"
                  placeholder={translate("Enter your password")}
                  ref={passwordRef}
                  value={state.password}
                  onChange={handleInput}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEnter("password");
                    }
                  }}
                />
                <p
                  className={`error-message ${errors.password ? "active" : ""}`}
                >
                  {errors.password}
                </p>
              </div>

              <div className="login-password mt-3 mb-3">
                <ExtensionApi
                  googlerecaptcha2="google-recaptcha2"
                  CaptchaPassed={setIsCaptchaPassed}
                />
              </div>
              <div className="remember-wrapper">
                <div className="form-check remember-me">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {translate("Remember for 30 days")}
                  </label>
                </div>
                <Link
                  style={{ fontSize: "16px", color: "#45AAF0" }}
                  to="/forgetPassword"
                >
                  {translate("Forget Password")}
                </Link>
              </div>
              <div className="signIn-btn">
                {loading && <Loader size={20} height={10} />}
                {!loading && (
                  <button onClick={handleLogin}>{translate("Sign In")}</button>
                )}
              </div>
              <div className="signUp-link">
                <p>
                  {translate("Don't have an account?")}{" "}
                  <Link
                    style={{ fontSize: "16px", color: "#45AAF0" }}
                    to="/owner-register"
                  >
                    {translate("Sign Up")}
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-6 col-md-6  d-none d-lg-block d-md-block">
            <RegisterImage type={"login"} />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default OwnerLogin;
