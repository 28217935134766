import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addBoat } from "./boatSlice";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CircularProgress, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function BoatCarousel() {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const location = useLocation();
  const [multiImageSlider, setMultiImageSlider] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (url) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", url);
      const boatData = res.data;
      if (boatData?.boat_types) {
        const boatTypesArray = Object.values(boatData.boat_types);
        const isMobile = window.matchMedia("(max-width: 767px)").matches;
        const subarraySize = isMobile ? 1 : 3;
        const newMultiImageSlider = [];

        for (let i = 0; i < boatTypesArray.length; i += subarraySize) {
          const subarray = boatTypesArray.slice(i, i + subarraySize);
          newMultiImageSlider.push(...subarray); // Flattening here
        }

        setMultiImageSlider(newMultiImageSlider);
      } else {
        setMultiImageSlider([]); // If no boat types, ensure empty array
      }
      dispatch(addBoat(boatData));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      const res = location.state;
      setMultiImageSlider(res.data.boat_types || []);
      dispatch(addBoat(res.data));
    } else if (id && slug) {
      fetchData(`search-boat-type/${id}/${slug}`);
    } else {
      fetchData("boat");
    }
  }, [id, slug, location.state]);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 3 },
    desktop: { breakpoint: { max: 1024, min: 768 }, items: 3 },
    tablet: { breakpoint: { max: 768, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button onClick={onClick} className=" boat-button right-arrow">
        <FaChevronRight />
      </button>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button onClick={onClick} className=" boat-button left-arrow">
        <FaChevronLeft />
      </button>
    );
  };

  return (
    <div className="boat-carousel-wrapperr">
      {loading ? (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      ) : (
        <>
          {multiImageSlider.length > 0 ? (
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              responsive={responsive}
              customRightArrow={<CustomRightArrow />}
              customLeftArrow={<CustomLeftArrow />}
            >
              {multiImageSlider.map((item) => (
                <Link
                  className="link-wrapper"
                  key={item.id}
                  to={`/boat/search-location/${item.id}/${item.slug}`}
                >
                  <div className="boat-type">
                    <div className="boat-type-content">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="boat-type-image"
                      />
                      <h2 className="boat-type-heading">{item.name}</h2>
                    </div>
                  </div>
                </Link>
              ))}
            </Carousel>
          ) : (
            <p className="text-center fw-bold">No property types available.</p>
          )}
        </>
      )}
    </div>
  );
}

export default BoatCarousel;
